import { get, post, put } from './utils';

export async function getIncidents({  pageSize, pageNumber, fromDate, toDate }) {
    return get(`/incidents?page=${pageNumber}&size=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`);
}

export async function getIncident(id) {
    return get(`/incidents/${id}`);
}

export async function incidentReply(data) {
    return post(`/incidents/reply`, data);
}

export async function incidentMessages(id) {
  return get(`/incidents/messages?incidentId=${id}`)
}

export async function closeIncidentStatus(data) {
    return post(`/incidents/close`, data)
}

export async function openIncidentStatus(data) {
    return post(`/incidents/open`, data)
}

export async function updateIncidentStatus(data) {
  return post(`/incidents/update`, data)
}

export async function assignOfficer(data) {
  return post(`/incident-officer/add`, data)
}

export async function removeOfficer(data) {
  return post(`/incident-officer/remove`, data)
}

const token = localStorage.getItem("sessionToken");


export async function getTicketDetails(id) {
    return get(`/incident-tickets/${id}`);
}

export async function getTickets(page, size) {
    return get(`/incident-tickets?page=${page}&size=${size}`);
}

