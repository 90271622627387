import React, { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import TableContainer from '../../components/Common/TableContainer';
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query"
import { Col, Row, Card, CardBody } from "reactstrap";
import { getIncidentCategories, deleteIncidentCategoryApi } from "api/categories";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useRole } from "hooks/useRole"

function IncidentCategory() {
    const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole();

    const rootPath = '/categorydetails';

    React.useEffect(() => {
        document.title = "Incident Categories | CA Portal"
      }, [])

    const navigate = useNavigate();
    const [incidentCategories, setIncidentCategories] = useState([]);

    const [pagination, setPagination] = useState({
        pageSize: 100,
        pageNumber: 0,
    })

    const { mutate: mutateIncidentCategories } = useMutation(
        () => getIncidentCategories(pagination.pageNumber, pagination.pageSize),
        {
            onSuccess: res => {
                setIncidentCategories(res.data)
                console.log(res)
            },
            onSettled: () => { },
        }
    )

    useEffect(() => {
        mutateIncidentCategories({ ...pagination })
    }, [pagination])



    const columns = useMemo(
        () => [
            {
                Header: '#ID',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
           
        ],
        []
    );

    const handleCreate = () => {
        navigate("/createincidentcategories");
    };

    const onEdit = (record) => {
        navigate(`${rootPath}/${record.original.id}/edit`);
    };
      
    const handleViewDetails = (record) => {
        navigate(`/categorydetails/${record.original.id}`);
      };

    const handleDelete = async (id) => {
        try {
          await deleteIncidentCategoryApi(id);
          mutateIncidentCategories()
         
        } catch (error) {
            alert("Error deleting category. Please try again")

        }
    };
   
    const onChangePagination = ({ page, limit }) => {
        setPagination({ ...pagination, pageNumber: page, pageSize: limit });
    };

    return (
        <div className="page-content">
         <Breadcrumbs title="Incidents" breadcrumbItem="Incident Categories" />
            <div className="container-fluid">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <TableContainer
                                    showView
                                    showEdit={isAdmin || isSuperAdmin}
                                    hasCreate={isAdmin || isSuperAdmin}
                                    showDelete={isAdmin || isSuperAdmin} 
                                    columns={columns}
                                    data={incidentCategories || []}
                                    isGlobalFilter={true}
                                    isAddOptions={false}
                                    customPageSize={5}
                                    isPagination={true}
                                    filterable={false}
                                    iscustomPageSizeOptions={true}
                                    addText="Create Incident  Category"
                                    tableClass="align-middle table-nowrap table-check"
                                    theadClass="table-light"
                                    pagination="pagination pagination-rounded justify-content-end mb-2"
                                    handleEdit={onEdit}
                                    handleCreate={handleCreate}
                                    handleView={handleViewDetails}
                                    handleDelete={handleDelete}
                                    fetchData={mutateIncidentCategories}
                                    onChangePagination={onChangePagination}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

IncidentCategory.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default IncidentCategory;
