import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getContentCategoryDetailsById } from "api/categories"
import { Button, Card, CardBody, Container, Row, Col, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"

function ContentCategoryDetails() {
  React.useEffect(() => {
    document.title = "Incident Categories | CA Portal"
  }, [])

  const { id } = useParams()
  const [content, setContent] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await getContentCategoryDetailsById(id)
        if (response && response.data) {
          const formattedData = formatDates(response?.data);

          setContent(formattedData)
        }
      } catch (error) {
        console.error("Error fetching content category details:", error)
      }
    }

    if (id) {
      fetchContent()
    }
  }, [id])

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return "";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDates = (data) => {
    const formattedData = { ...data };
    formattedData.createdAt = formatDate(data.createdAt);
    return formattedData;
  };

  if (!content) {
    return <p>Loading...</p>
  }

  const handleBack = () => {
    navigate("/contentcategorylist")
  }

  const aliases = {
    id: "ID",
    name: "Name",
    description: "Description",
    imageUrl: "Image",
    status: "Status",
    createdAt: "Date Created"
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs
            title="Content Category"
            breadcrumbItem="Content Category Details"
          />
          <Button
            type="button"
            color="primary"
            className="btn"
            onClick={handleBack}
          >
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>

        <Row className="justify-content-center">
          <Col lg="12">
            <Card>
              <CardBody>
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content &&
                      Object.entries(content)
                        .filter(([key]) => key !== "deleted")
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>
                              <strong>{aliases[key] || key}</strong>
                            </td>
                            <td>
                              {typeof value === "object"
                                ? JSON.stringify(value)
                                : value}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContentCategoryDetails
