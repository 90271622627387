// ContentChart.js
import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const SurveyDonutChart = ({ dataColors, data }) => {
  const aggregatedData = data.reduce((acc, item) => {
    acc.totalSurveys += item.totalSurveys;
    acc.totalRespondents += item.totalRespondents;
    acc.totalNewSurveys += item.totalNewSurveys;
    acc.totalRunningSurveys += item.totalRunningSurveys;
    acc.totalClosedSurveys += item.totalClosedSurveys;
    return acc;
  }, { 
    totalSurveys: 0,
    totalRespondents: 0,
    totalNewSurveys: 0,
    totalRunningSurveys: 0,
    totalClosedSurveys: 0
  });

  if (
    aggregatedData.totalSurveys === 0 &&
    aggregatedData.totalRespondents === 0 &&
    aggregatedData.totalNewSurveys === 0 &&
    aggregatedData.totalRunningSurveys === 0 &&
    aggregatedData.totalClosedSurveys === 0 
  ) {
    return <div>No data available for the selected months.</div>;
  }

  const donutApexChartColors = getChartColorsArray(dataColors);

  const series = [
    aggregatedData.totalSurveys,
    aggregatedData.totalRespondents,
    aggregatedData.totalNewSurveys,
    aggregatedData.totalRunningSurveys,
    aggregatedData.totalClosedSurveys
  ];

  const options = {
    labels: [
      "Total Surveys",
      "Total Respondents",
      "Total New Surveys",
      "Total Running Surveys",
      "Total Closed Surveys"
    ],
    colors: donutApexChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="396"
    />
  );
};

export default SurveyDonutChart;
