import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const Splinearea = ({ dataColors, data }) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Total Incidents",
      data: data.map((item) => item.totalIncidents),
    },
    {
      name: "Total New Incidents",
      data: data.map((item) => item.totalNewIncidents),
    },
    {
      name: "Total Pending Incidents",
      data: data.map((item) => item.totalPendingIncidents),
    },
    {
      name: "Total Closed Incidents",
      data: data.map((item) => item.totalClosedIncidents),
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: spineareaChartColors,
    xaxis: {
      categories: data.map((item) => item.monthName),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },

      tickAmount: 5,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    
  };

  return (
        <ReactApexChart 
        options={options} 
        series={series} 
        type="bar" 
        height={250} 
        />
  );
};

export default Splinearea;
