import React, { useEffect, useRef, useCallback } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useRole } from "hooks/useRole"

const SidebarContent = props => {
  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add("mm-active")
          parent3.childNodes[0].classList.add("mm-active")
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add("mm-show")
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show")
              parent5.childNodes[0].classList.add("mm-active")
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active")
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement
            if (parent4) {
              parent4.classList.remove("mm-show")
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show")
                parent5.childNodes[0].classList.remove("mm-active")
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {(isAdmin || isSuperAdmin || isEditor || isAnalyst) && (
              <>
                <li>
                  <Link to="/dashboard">
                    <i className="bx bxs-dashboard"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to="/incidents&categories" className="has-arrow">
                <i className="bx bxs-error"></i>
                <span>{props.t("Incidents & Categories")}</span>
              </Link>
              <ul className="sub-menu">
                {(isAdmin || isSuperAdmin || isAnalyst) && (
                  <>
                    <li>
                      <Link to="/incidents">
                        <i className=" bx bxs-error-alt"></i>
                        <span>{props.t("Incidents")}</span>
                      </Link>
                    </li>
                  </>
                )}

                {(isAdmin || isSuperAdmin || isAnalyst || isEditor) && (
                  <>
                    <li>
                      <Link to="/incidentcategory">
                        <i className="bx bxs-bookmark"></i>
                        <span>{props.t("Incident Categories")}</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>

            {(isAnalyst || isAdmin || isSuperAdmin || isEditor) && (
              <>
                <li>
                  <Link to="/surveys">
                    <i className="bx bxs-message-rounded-detail"></i>
                    <span>{props.t("Surveys")}</span>
                  </Link>
                </li>
              </>
            )}

            <li>
              <Link to="/userfeedback" className="has-arrow">
                <i className="bx bxs-file"></i>
                <span>{props.t("App Feedback")}</span>
              </Link>
              <ul className="sub-menu">
                {(isAdmin || isSuperAdmin || isAnalyst) && (
                  <>
                    <li>
                      <Link to="/appfeedback">
                        <i className="bx bxs-user"></i>
                        <span>{props.t("User Feedback")}</span>
                      </Link>
                    </li>
                  </>
                )}
                {(isAdmin || isSuperAdmin || isAnalyst || isEditor) && (
                  <>
                    <li>
                      <Link to="/visitreasons">
                        <i className="bx bxs-microphone"></i>
                        <span>{props.t("App Visit Reasons")}</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>

            <li>
              <Link to="/content&categories" className="has-arrow">
                <i className=" bx bxs-cloud-upload"></i>
                <span>{props.t("Content & Categories")}</span>
              </Link>
              <ul className="sub-menu">
                {(isAdmin || isSuperAdmin || isEditor || isAnalyst) && (
                  <li>
                    <Link to="/content">
                      <i className="bx bxs-bell-ring"></i>
                      <span>{props.t("Alerts & Advisories")}</span>
                    </Link>
                  </li>
                )}

                {(isAdmin || isSuperAdmin || isAnalyst || isEditor) && (
                  <li>
                    <Link to="/contentcategorylist">
                      <i className=" bx bxs-bookmark-minus"></i>
                      <span>{props.t("Categories")}</span>
                    </Link>
                  </li>
                )}

                {(isAdmin || isSuperAdmin || isEditor || isAnalyst) && (
                  <>
                    <li>
                      <Link to="/learningcentre">
                        <i className="bx bxs-video-plus"></i>
                        <span>{props.t("Learning Center")}</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </li>

            {(isEditor || isAdmin || isSuperAdmin || isAnalyst) && (
              <>
                <li>
                  <Link to="/forum">
                    <i className=" bx bxs-chat"></i>
                    <span>{props.t("Forum")}</span>
                  </Link>
                </li>
              </>
            )}

            {(isAdmin || isSuperAdmin) && (
              <li>
                <Link to="/usermanagement" className="has-arrow">
                  <i className="bx bxs-group"></i>
                  <span>{props.t("User Management")}</span>
                </Link>
                <ul className="sub-menu">
                  {(isAdmin || isSuperAdmin) && (
                    <>
                      <li>
                        <Link to="/users">
                          <i className=" bx bxs-user-check"></i>
                          <span>{props.t("Portal Users")}</span>
                        </Link>
                      </li>
                    </>
                  )}
                  {(isAdmin || isSuperAdmin || isAnalyst ) && (
                    <>
                      <li>
                        <Link to="/appusers">
                          <i className=" bx bxs-user-voice"></i>
                          <span>{props.t("App Users")}</span>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
