import React, { useState, useEffect, useRef } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Label,
  Modal,
  ModalBody,
  Input,
  Table,
  Row,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  FormFeedback,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"

import {
  getIncident,
  incidentReply,
  closeIncidentStatus,
  openIncidentStatus,
  updateIncidentStatus,
  assignOfficer,
  removeOfficer,
  incidentMessages,
} from "api/incidents"
import { getUsers } from "api/users"
import { useParams, useNavigate } from "react-router-dom"
import { useMutation } from "react-query"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import Chat from "Chat/Chat"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { useFormik } from "formik"
import * as Yup from "yup"
import * as XLSX from "xlsx"
import { useRole } from "hooks/useRole"

const IncidentDetails = () => {
  document.title = "Incident | Details"

  const { isAdmin, isSuperAdmin } = useRole()

  const [settingsMenu, setSettingsMenu] = useState(false)
  const { id } = useParams()
  const [incidentData, setIncidentData] = useState({})
  const [unfilteredData, setUnfilteredData] = useState({})
  const [showReplyModal, setShowReplyModal] = useState(false)
  const [showAllDetails, setShowAllDetails] = useState(false)
  const [unformattedDate, setUnformattedDate] = useState("")
  const [replyText, setReplyText] = useState("")
  const [messages, setMessages] = useState([])
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [usersData, setUsersData] = useState([])
  const [showAssignOfficerModal, setShowAssignOfficerModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const inputRef = useRef(null)
  const initialDisplayCount = 5
  const navigate = useNavigate()

  const aliases = {
    id: "ID",
    subject: "Subject",
    reportType: "Report Type",
    refNumber: "Reference Number",
    status: "Incident Status",
    "ticket.status": "Ticket Status",
    location: "Location",
    description: "Description",
    place: "Place",
    otherInfo: "Other Information",
    dateTime: "Date/Time",
    createdAt: "Created At",
    platformUsed: "Platform Used",
    deleted: "Deleted",
    userId: "User ID",
    "ticket.id": "Ticket ID",
    "ticket.ticketNumber": "Ticket Number",
    "ticket.description": "Ticket Description",
    "ticket.deleted": "Ticket Deleted",
    "ticket.ticketStatusId": "Ticket Status ID",
    "ticket.createdAt": "Ticket Created At",
    "reporter.id": "Reporter ID",
    "reporter.firstName": "Reporter First Name",
    "reporter.lastName": "Reporter Last Name",
    "reporter.nickName": "Reporter Nickname",
    "reporter.email": "Reporter Email",
    "reporter.mobile": "Reporter Mobile",
    "reporter.relationship": "Reporter Relationship",
    "reporter.sex": "Reporter Sex",
    "reporter.age": "Reporter Age",
    "reporter.location": "Reporter Location",
    "reporter.preferredLanguage": "Reporter Preferred Language",
    "reporter.createdAt": "Reporter Created At",
    "reporter.deleted": "Reporter Deleted",
    "victim.id": "Victim ID",
    "victim.name": "Victim Name",
    "victim.nickNames": "Victim Nicknames",
    "victim.email": "Victim Email",
    "victim.mobile": "Victim Mobile",
    "victim.sex": "Victim Sex",
    "victim.age": "Victim Age",
    "victim.location": "Victim Location",
    "victim.preferredLanguage": "Victim Preferred Language",
    "destVulnerabilityAddress.ip": "Destination IP",
    "destVulnerabilityAddress.dns": "Destination DNS",
    "destVulnerabilityAddress.port": "Destination Port",
    "sourceVulnerabilityAddress.ip": "Source IP",
    "sourceVulnerabilityAddress.dns": "Source DNS",
    "sourceVulnerabilityAddress.port": "Source Port",
    "incidentType.id": "Incident Type ID",
    "incidentType.name": "Incident Type",
    "incidentType.deleted": "Incident Type Deleted",
    "incidentCategory.id": "Incident Category ID",
    "incidentCategory.cid": "Incident Category CID",
    "incidentCategory.name": "Incident Category Name",
    "incidentCategory.type": "Incident Category Type",
    "incidentCategory.description": "Incident Category Description",
    "incidentCategory.imageUrl": "Incident Category Image URL",
    "incidentCategory.deleted": "Incident Category Deleted",
  }

  const excludeKeys = [
    "deleted",
    "userId",
    "ticket.deleted",
    "ticket.id",
    "ticket.description",
    "ticket.ticketStatusId",
    "reported.createdAt",
    "reporter.deleted",
    "incidentType.id",
    "incidentType.deleted",
    "incidentCategory.deleted",
    "incidentCategory.id",
    "incidentCategory.cid",
    "incidentCategory.description",
    "incidentCategory.imageUrl",
    "officers",
  ]

  const formatDate = dateString => {
    const date = new Date(dateString)
    return date.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  }

  const formatDates = data => {
    const formattedData = { ...data }

    formattedData.createdAt = formatDate(data.createdAt)
    formattedData.reporter.createdAt = formatDate(
      data.reporter.createdAt
    ).toLocaleString()
    formattedData.ticket.createdAt = formatDate(
      data.ticket.createdAt
    ).toLocaleString()

    return formattedData
  }

  const filterData = (data, excludeKeys) => {
    const filteredData = { ...data }
    excludeKeys.forEach(key => {
      const keyParts = key.split(".")
      if (keyParts.length === 1) {
        delete filteredData[key]
      } else if (keyParts.length === 2) {
        if (filteredData[keyParts[0]]) {
          delete filteredData[keyParts[0]][keyParts[1]]
        }
      }
    })
    return filteredData
  }

  const filterNullValues = data => {
    if (data === null || data === undefined) {
      return undefined
    }

    if (typeof data !== "object") {
      return data
    }

    const filteredData = Array.isArray(data) ? [] : {}

    Object.keys(data).forEach(key => {
      const value = data[key]
      if (value !== null && typeof value === "object") {
        const nestedFilteredData = filterNullValues(value)
        if (
          nestedFilteredData !== undefined &&
          Object.keys(nestedFilteredData).length > 0
        ) {
          filteredData[key] = nestedFilteredData
        }
      } else if (value !== null) {
        filteredData[key] = value
      }
    })

    return filteredData
  }

  const fetchIncidentDetails = async () => {
    try {
      const response = await getIncident(id)
      if (response.status === 200) {
        const formattedData = formatDates(response.data)
        setUnfilteredData(formattedData)
        console.log(formattedData)
        const filteredData = filterData(formattedData, excludeKeys)
        const cleanedData = filterNullValues(filteredData)
        setIncidentData(cleanedData)
      }
    } catch (error) {
      console.error("Error fetching incident details:", error)
    }
  }

  useEffect(() => {
    fetchIncidentDetails()
  }, [id])

  const flattenObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : ""
      if (typeof obj[k] === "object" && obj[k] !== null) {
        Object.assign(acc, flattenObject(obj[k], pre + k))
      } else {
        acc[pre + k] = obj[k]
      }
      return acc
    }, {})

  const generatePDF = () => {
    const pdf = new jsPDF()

    pdf.setFontSize(18)
    pdf.text(`Incident Details - ${id}`, 10, 10)

    const flatData = flattenObject(incidentData)

    const headers = Object.keys(flatData)
    const data = Object.values(flatData).map(value =>
      value !== null ? value.toString() : ""
    )

    const transposedTable = headers.map((header, index) => [
      aliases[header] || header,
      data[index],
    ])

    const options = {
      startY: 20,
      margin: { top: 20 },
      headStyles: { fillColor: [200, 200, 200] },
      columnStyles: { 0: { cellWidth: "auto" } },
    }

    pdf.autoTable({
      head: [["Field", "Value"]],
      body: transposedTable,
      ...options,
    })

    return pdf
  }

  const handleDownload = format => {
    if (format === "pdf") {
      const pdf = generatePDF()
      pdf.save(`incident_${id}.pdf`)
    } else if (format === "excel") {
      const flatData = flattenObject(incidentData)
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet([flatData])
      XLSX.utils.book_append_sheet(workbook, worksheet, "Incident Details")
      XLSX.writeFile(workbook, `incident_${id}.xlsx`)
    }
  }

  const handlePrint = () => {
    const pdf = generatePDF()
    pdf.autoPrint()
    pdf.output("dataurlnewwindow")
  }

  const getMessages = async () => {
    try {
      const response = await incidentMessages(incidentData.id)
      if (response.status === 200) {
        setMessages(response.data)
      } else {
        console.error("Error fetching messages:", response.data.message)
      }
    } catch (error) {
      console.error("Error fetching messages:", error)
    }
  }
  const handleReplyClick = async () => {
    setShowReplyModal(true)
    await getMessages()
  }

  const initialValues = {
    replyText: "",
    incidentId: incidentData?.id,
    appUserId: localStorage.getItem("appUserId"),
    reporterId: incidentData?.reporter?.id,
  }

  const validationSchema = Yup.object().shape({
    replyText: Yup.string().required("Reply text is required"),
  })

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const replyData = {
        text: values.replyText,
        incidentId: incidentData.id,
        appUserId: localStorage.getItem("appUserId"),
        reporterId: incidentData?.reporter?.id,
      }

      await incidentReply(replyData)
      // alert("Reply sent successfully!")
      // setShowReplyModal(false)
      getMessages()
      resetForm()
    } catch (error) {
      console.error("Error submitting reply:", error)
      alert("Error submitting reply. Please try again")
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const handleInput = event => {
    event.target.style.height = "auto"
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [formik.values.replyText])

  const handleCloseIncident = async e => {
    try {
      const formattedData = { ...incidentData }
      if (formattedData.createdAt) {
        formattedData.createdAt = unformattedDate
      }
      if (formattedData.ticket && formattedData.ticket.createdAt) {
        formattedData.ticket.createdAt = unformattedDate
      }
      if (formattedData.reporter && formattedData.reporter.createdAt) {
        formattedData.reporter.createdAt = unformattedDate
      }
      const response = await closeIncidentStatus(formattedData)
      if (response.status === 200) {
        console.log("Incident Closed Successfully", response.data)
        alert("Incident Closed Successfully")
        fetchIncidentDetails()
      }
    } catch (error) {
      console.error("Error closing:", error)
      alert("Error closing incident. Please try again")
    }
  }

  const handleOpenIncident = async e => {
    try {
      const formattedData = { ...incidentData }
      if (formattedData.createdAt) {
        formattedData.createdAt = unformattedDate
      }
      if (formattedData.ticket && formattedData.ticket.createdAt) {
        formattedData.ticket.createdAt = unformattedDate
      }
      if (formattedData.reporter && formattedData.reporter.createdAt) {
        formattedData.reporter.createdAt = unformattedDate
      }
      const response = await openIncidentStatus(formattedData)
      if (response.status === 200) {
        console.log("Incident Opened Successfully", response.data)
        alert("Incident Opened Successfully")
        fetchIncidentDetails()
      }
    } catch (error) {
      console.error("Error opening:", error)
      alert("Error opening incident. Please try again")
    }
  }

  const initialValuesUpdate = {
    updateDescription: "",
    incidentId: incidentData.id,
  }

  const validationSchemaUpdate = Yup.object().shape({
    updateDescription: Yup.string().required("Description is required"),
  })

  const forMik = useFormik({
    initialValues: initialValuesUpdate,
    validationSchema: validationSchemaUpdate,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const updateData = {
          description: values.updateDescription,
          incidentId: incidentData.id,
        }

        await updateIncidentStatus(updateData)
        alert("Incident Updated Successfully!")
        setShowUpdateModal(false)
        resetForm()
      } catch (error) {
        console.error("Error updating incident:", error)
        alert("Error updating incident. Please try again")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleUpdateModalToggle = () => {
    setShowUpdateModal(!showUpdateModal)
  }
  const renderTableCells = (data, prefix = "") => {
    const renderableData = Object.entries(data).slice(
      0,
      showAllDetails ? undefined : initialDisplayCount
    )

    return renderableData.map(([key, value]) => {
      if (value === null) {
        return null
      }
      const newKey = prefix ? `${prefix}.${key}` : key
      const displayName = aliases[newKey] || newKey

      if (typeof value === "object" && value !== null) {
        return renderTableCells(value, newKey)
      } else {
        return (
          <tr key={newKey}>
            <td>{displayName}</td>
            <td>{value !== null ? value.toString() : ""}</td>
          </tr>
        )
      }
    })
  }

  const renderAllDetailsButton = () => {
    if (Object.entries(incidentData).length > initialDisplayCount) {
      return (
        <Button
          color="primary"
          onClick={() => setShowAllDetails(!showAllDetails)}
          style={{ margin: "20px 0" }}
        >
          {showAllDetails ? "Show Less Details" : "Show All Details"}
        </Button>
      )
    }
    return null
  }

  const [pagination, setPagination] = useState({
    pageSize: 10000,
    pageNumber: 0,
  })

  const { mutate: mutateUsers } = useMutation(
    () => getUsers(pagination.pageNumber, pagination.pageSize),
    {
      onSuccess: res => {
        const filteredData = res.data.filter(
          item =>
            !item.roles.some(
              role =>
                role.roleName === "ROLE_USER" &&
                "ROLE_SUPER_ADMIN" &&
                "ROLE_ADMIN"
            )
        )
        setUsersData(filteredData)
        console.log(filteredData)
      },
      onSettled: () => {},
    }
  )

  useEffect(() => {
    mutateUsers({ ...pagination })
  }, [pagination, showAssignOfficerModal])

  const handleAssignOfficerModalToggle = () => {
    if (!showAssignOfficerModal) {
      const assignedOfficerIds = unfilteredData.officers.map(
        officer => officer.user.id
      )
      const availableUsers = usersData.filter(
        user => !assignedOfficerIds.includes(user.id)
      )
      setUsersData(availableUsers)
    }
    setShowAssignOfficerModal(!showAssignOfficerModal)
  }

  const handleAssignOfficer = async () => {
    try {
      if (selectedUser) {
        await assignOfficer({ incidentId: id, userId: selectedUser.value })
        alert("Officer assigned successfully!")
        fetchIncidentDetails()
        setSelectedUser(null)
      } else {
        alert("Please select an officer to assign.")
      }
    } catch (error) {
      console.error("Error assigning officer:", error)
      alert("Error assigning officer. Please try again")
    }
  }

  useEffect(() => {
    setSelectedUser(null)
  }, [])

  const handleRemoveOfficer = async officerId => {
    try {
      const response = await removeOfficer({
        incidentId: id,
        userId: String(officerId),
      })
      if (response.status === 200) {
        fetchIncidentDetails()
        alert("Officer removed successfully!")
      } else {
        console.error("Error removing officer:", response.data)
        alert("Error removing officer. Please try again later.")
      }
    } catch (error) {
      console.error("Error removing officer:", error)
      alert("Error removing officer. Please try again later.")
    }
  }

  const handleBack = () => {
    navigate("/incidents")
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs title="Incident" breadcrumbItem="Incident Details" />
          <Button
            type="button"
            color="primary"
            className="btn"
            onClick={handleBack}
          >
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        <Row>
          <Col lg={9}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end">
                  {isAdmin || isSuperAdmin ? (
                    <Dropdown
                      isOpen={settingsMenu}
                      toggle={() => setSettingsMenu(!settingsMenu)}
                    >
                      <DropdownToggle color="primary" caret>
                        Actions
                      </DropdownToggle>
                      <DropdownMenu>
                        {(isAdmin || isSuperAdmin) && (
                          <>
                            {incidentData?.ticket?.status === "Closed" && (
                              <DropdownItem onClick={handleOpenIncident}>
                                Open Incident Ticket
                              </DropdownItem>
                            )}

                            <DropdownItem onClick={handleUpdateModalToggle}>
                              Update Incident Ticket
                            </DropdownItem>

                            <DropdownItem onClick={handleReplyClick}>
                              Reply to Incident
                            </DropdownItem>
                            {incidentData?.ticket?.status !== "Closed" && (
                              <>
                                <DropdownItem
                                  onClick={() =>
                                    setShowAssignOfficerModal(true)
                                  }
                                >
                                  Assign Officer to Incident
                                </DropdownItem>

                                <DropdownItem onClick={handleCloseIncident}>
                                  Close Incident Ticket
                                </DropdownItem>
                              </>
                            )}
                          </>
                        )}

                        <DropdownItem onClick={() => handleDownload("pdf")}>
                          Download as PDF
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDownload("excel")}>
                          Download as Excel
                        </DropdownItem>
                        <DropdownItem onClick={handlePrint}>Print</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : null}
                </div>

                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableCells(incidentData)}</tbody>
                </Table>
                {renderAllDetailsButton()}
              </CardBody>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <CardBody>
                <CardTitle className="h5 mb-2">Assigned Officers</CardTitle>
                <Table className="table mb-0">
                  <tbody>
                    {unfilteredData?.officers &&
                      unfilteredData?.officers.map(officer => (
                        <tr key={officer?.id}>
                          <td>{`${officer?.user?.firstName} ${officer?.user?.lastName}`}</td>
                          <td>
                            <i
                              className="mdi mdi-delete text-danger font-size-18"
                              id="deletetooltip"
                              onClick={() =>
                                handleRemoveOfficer(officer.user.id)
                              }
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip"
                            >
                              Remove Officer
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={showReplyModal}
        toggle={() => setShowReplyModal(!showReplyModal)}
        centered={true}
        className="incident-chat-modal"
        style={{ maxWidth: "600px", margin: "auto" }}
      >
        <ModalBody className="incident-chat-body" style={{ padding: "20px" }}>
          <h3
            style={{
              textAlign: "center",
              borderBottom: "2px solid #000",
              paddingBottom: "10px",
              marginBottom: "20px",
            }}
          >
            Reply to Incident
          </h3>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: message.appUser ? "flex-end" : "flex-start",
                marginBottom: "10px",
              }}
            >
              <div
                className={`p-2 rounded shadow-sm ${
                  message.appUser
                    ? "bg-primary text-white"
                    : "bg-light text-dark"
                }`}
                style={{
                  maxWidth: "80%",
                  textAlign: message.appUser ? "right" : "left",
                }}
              >
                <p style={{ margin: 0 }}>
                  {message.appUser
                    ? `You sent: ${message.text}`
                    : `Received: ${message.text}`}
                </p>
              </div>
            </div>
          ))}
        </ModalBody>
        <div
          style={{
            padding: "20px",
            borderTop: "1px solid #ccc",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Input
                type="textarea"
                name="replyText"
                id="replyText"
                innerRef={inputRef}
                onChange={event => {
                  formik.handleChange(event)
                  handleInput(event)
                }}
                onBlur={formik.handleBlur}
                value={formik.values.replyText}
                invalid={formik.touched.replyText && !!formik.errors.replyText}
                style={{
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow:
                    formik.touched.replyText && formik.errors.replyText
                      ? "0 0 0 2px red"
                      : "none",
                }}
              />
              <FormFeedback>{formik.errors.replyText}</FormFeedback>
            </FormGroup>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                color="primary"
                disabled={formik.isSubmitting}
                style={{
                  borderRadius: "10px",
                  padding: "10px 20px",
                }}
              >
                Send
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={showUpdateModal}
        toggle={handleUpdateModalToggle}
        centered={true}
      >
        <ModalBody>
          <Form onSubmit={forMik.handleSubmit}>
            <h2>Update Incident Ticket</h2>
            <FormGroup>
              <Label for="updateDescription">Description</Label>
              <Input
                type="textarea"
                id="updateDescription"
                name="updateDescription"
                value={forMik.values.updateDescription}
                onChange={forMik.handleChange}
                onBlur={forMik.handleBlur}
                invalid={
                  forMik.touched.updateDescription &&
                  forMik.errors.updateDescription
                }
              />
              {forMik.touched.updateDescription &&
                forMik.errors.updateDescription && (
                  <FormFeedback>{forMik.errors.updateDescription}</FormFeedback>
                )}
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              disabled={forMik.isSubmitting}
            >
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showAssignOfficerModal}
        toggle={handleAssignOfficerModalToggle}
        centered={true}
      >
        <ModalBody>
          <Form onSubmit={forMik.handleSubmit}>
            <FormGroup>
              <Label for="selectedUser">
                Select Officer to assign to incident below
              </Label>
              <Select
                value={selectedUser}
                onChange={selectedOption => {
                  setSelectedUser(selectedOption)
                }}
                options={usersData
                  .filter(option => {
                    return !unfilteredData.officers.some(
                      officer => officer.user.id === option.id
                    )
                  })
                  .map(option => ({
                    label: `${option.firstName} ${option.lastName}`,
                    value: option.id,
                  }))}
                classNamePrefix="react-select"
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    color: "black",
                    backgroundColor: state.isSelected ? "#2684FF" : "white",
                    "&:hover": {
                      backgroundColor: "#2684FF",
                      color: "white",
                    },
                  }),
                }}
              />
            </FormGroup>
            <Button
              className="mt-3"
              color="primary"
              onClick={handleAssignOfficer}
            >
              Assign
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default IncidentDetails
