import React, { useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useMutation, useQueryClient } from 'react-query';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

const series1 = [
  { name: "Incidents", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f1b44c"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Dependants Chart
const series2 = [
  { name: "Dependants", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#00b3e4"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

//Defaulted Chart
const series3 = [
  { name: "Defaulted", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#50a5f1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: false }, x: { show: false }, marker: { show: false } },
}

const IncidentReports = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();





  const reports = [
    {
      icon: "mdi mdi-account",
      color: "primary",
      value: 0,
      desc: "Total Incidents",
      series: series1,
      options: options1,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
    },
    {
      title: "Dependants",
      icon: "mdi mdi-account-multiple",
      color: "info",
      value: 0,
      desc: "Total Dependants",
      series: series2,
      options: options2,
      arrowUpDown: 'mdi mdi-arrow-down ms-1 text-danger'
    },
    {
      title: "Defaulted",
      icon: "mdi mdi-account-alert",
      color: "warning",
      value: 0,
      desc: "Defaulted Members",
      series: series3,
      options: options3,
      arrowUpDown: 'mdi mdi-arrow-up ms-1 text-success'
    },
  ];

  const columnChartSeries = [
    {
      name: "Total Members",
      data: []
    },
    {
      name: "Dependants",
      data: []
    },
    {
      name: "Defaulted",
      data: []
    },
  ];



 

  const onSearch = (data) => {
   
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row>
        </Row>
        <Row>
          <Col md={8}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4"> Column Chart </CardTitle>
                {/* <Apaexlinecolumn dataColors='["--bs-primary","--bs-success","--bs-warning"]' series={columnChartSeries} categories="" /> */}
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">New Members</CardTitle>
                {/* <LineApexChart dataColors='["--bs-primary","--bs-success"]' /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default IncidentReports