import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import { useFormik } from "formik";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getSurveyDetailsById, updateSurvey } from "../../api/survey";

const EditSurvey = () => {
  useEffect(() => {
    document.title = "Edit Survey | CA Portal";
  }, []);

  const { id: stringId } = useParams();
  const id = parseInt(stringId, 10);
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState(null);
  const [error, setError] = useState(null);
  const [optionsError, setOptionsError] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await getSurveyDetailsById(id);
        setSurveyData(response.data);
      } catch (error) {
        console.error("Error fetching survey details:", error);
      }
    };
    fetchSurveyData();
  }, [id]);

  useEffect(() => {
    if (surveyData) {
      formik.setValues({
        title: surveyData.title || "",
        description: surveyData.description || "",
        type: surveyData.type || "",
        options: surveyData.options
          ? surveyData.options.map(option => option.content)
          : [""],
      });
    }
  }, [surveyData]);

  const handleAddOption = () => {
    formik.setFieldValue("options", [...formik.values.options, ""]);
  };

  const handleDeleteOption = index => {
    const updatedOptions = [...formik.values.options];
    updatedOptions.splice(index, 1);
    formik.setFieldValue("options", updatedOptions);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      type: "",
      options: [""],
    },
    onSubmit: async (values, { setSubmitting }) => {
      setError(null);
      setOptionsError(null);

      if (
        (values.type === "Multiple" || values.type === "Single") &&
        values.options.length < 2
      ) {
        setOptionsError("At least two options are required for this survey type.");
        setSubmitting(false);
        return;
      }

      try {
        const surveyData = {
          title: values.title,
          description: values.description,
          type: values.type,
          options: values.options.map(title => ({ content: title })),
        };
        await updateSurvey(id, surveyData);
        navigate("/surveys");
      } catch (error) {
        console.error("Error updating survey:", error);
        setError("Failed to update survey. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleInput = event => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [formik.values.description]);

  const handleBack = () => {
    navigate("/surveys");
  };

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between mb-3">
            <Breadcrumbs title="Surveys" breadcrumbItem="Edit Survey" />
            <Button
              type="button"
              color="primary"
              className="btn"
              onClick={handleBack}
            >
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </div>
          <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="title">Survey Title:</Label>
              <Input
                type="text"
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Survey Description:</Label>
              <Input
                type="textarea"
                id="description"
                name="description"
                innerRef={inputRef}
                value={formik.values.description}
                onChange={e => {
                  formik.handleChange(e);
                  handleInput(e);
                }}
                onBlur={formik.handleBlur}
                style={{ overflow: "hidden", resize: "none" }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="type">Survey Type:</Label>
              <Input
                type="select"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              >
                <option value="">Select Survey Type</option>
                <option value="Open">Open</option>
                <option value="Multiple">Multiple Choice</option>
                <option value="Single">Single Choice</option>
              </Input>
            </FormGroup>

            {formik.values.type && formik.values.type !== "Open" && (
              <FormGroup>
                {formik.values.options.map((title, index) => (
                  <div key={index} className="mt-3">
                    <Input
                      type="text"
                      value={title}
                      onChange={e =>
                        formik.setFieldValue(`options.${index}`, e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      required
                    />
                    <Button
                      color="danger"
                      onClick={() => handleDeleteOption(index)}
                      className="mt-2"
                    >
                      Delete Option
                    </Button>
                  </div>
                ))}
                <Button
                  color="success"
                  type="button"
                  onClick={handleAddOption}
                  className="mt-3"
                >
                  Add Option
                </Button>
              </FormGroup>
            )}

            <Button
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Update Survey
            </Button>
          </Form>
          {optionsError && (
            <Alert color="danger" className="mt-3">
              {optionsError}
            </Alert>
          )}
          {error && (
            <Alert color="danger" className="mt-3">
              {error}
            </Alert>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EditSurvey;
