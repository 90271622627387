import React from "react";
import ReactApexChart from "react-apexcharts";

const ContentChart = ({ data }) => {
  const series = [
    {
      name: "Total Content",
      data: data.map(item => item.totalContent),
    },
    {
      name: "Total New Content",
      data: data.map(item => item.totalNewContent),
    },
    {
      name: "Total Published Content",
      data: data.map(item => item.totalPublishedContent),
    },
    {
      name: "Total Archived Content",
      data: data.map(item => item.totalArchivedContent),
    },
    {
      name: "Total Unpublished Content",
      data: data.map(item => item.totalUnPublishedContent),
    },
    
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: data.map(item => item.monthName),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      },
      tickAmount: 5,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="350"
    />
  );
};

export default ContentChart;
