import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, CardBody, Card, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../../components/Common/TableContainer";
import { getUsers, deleteUserById } from "api/users";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useRole } from "hooks/useRole";

const AppUsers = () => {

  React.useEffect(() => {
    document.title = "App Users | CA Portal"
  }, []);

  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole();

  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: 100,
    pageNumber: 0,
  });

  const { mutate: mutateUsers } = useMutation(
    () => getUsers(pagination.pageNumber, pagination.pageSize), {
    onSuccess: (res) => {
      const filteredData = res.data.filter(item => !item.roles.some(role => role.roleName !== "ROLE_USER"));
      setUsersData(filteredData);
      console.log(filteredData);
    },
    onSettled: () => {},
  });

  useEffect(() => {
    mutateUsers({ ...pagination });
  }, [pagination]);

  const [usersData, setUsersData] = useState([]);

  const roleAliases = {
   
    "ROLE_USER": "User"
  };

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: (row) => {
          if (row.middleName) {
            return `${row.firstName} ${row.middleName} ${row.lastName}`;
          } else {
            return `${row.firstName} ${row.lastName}`;
          }
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Date of Birth",
        accessor: "dateOfBirth",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          });
          return formattedDate;
        },
      },
      {
        Header: "Role",
        accessor: (row) => {
          const roleName = row.roles.length > 0 ? row.roles[0].roleName : "-";
          return roleAliases[roleName] || roleName;
        },
      }
    ],
    []
  );

  const handleCreate = () => {
    navigate("/adduser/");
  };

  const handleViewDetails = (record) => {
    navigate(`/appuser/${record.original.id}`);
  };

  const handleEdit = (record) => {
    navigate(`/edit-user/${record.original.id}`)
  }

  const onChangePagination = ({ page, limit }) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: limit });
  };

  const handleDelete = async (id) => {
    try {
      const loggedInUserId = localStorage.getItem("appUserId");

      if (id === parseInt(loggedInUserId)) {
        alert("You cannot delete the logged-in user.");
        return;
      }
      
      await deleteUserById(id);
      alert("User deleted successfully.")
      mutateUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Error deleting user. Please try again")
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    showView
                    // showEdit
                    // hasCreate
                    showDelete={isAdmin || isSuperAdmin} 
                    columns={columns}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    data={usersData}
                    customPageSize={10}
                    isPagination={true}
                    filterable={false}
                    iscustomPageSizeOptions={true}
                    addText="Add New User"
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end mb-2"
                    handleCreate={handleCreate}
                    handleEdit={handleEdit}
                    handleView={handleViewDetails}
                    handleDelete={handleDelete}
                    fetchData={mutateUsers}
                    onChangePagination={onChangePagination} 
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

// Users.propTypes = {
//   preGlobalFilteredRows: PropTypes.any,
// };

export default AppUsers;
