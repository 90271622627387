import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getIncidentCategoryById } from "api/categories"
import { Button, Card, CardBody, CardTitle, CardText, Table, Container, Row, Col } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"

function CategoryDetails() {
  React.useEffect(() => {
    document.title = "Incident Categories | CA Portal"
  }, [])

  const { id } = useParams()
  const [category, setCategory] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await getIncidentCategoryById(id)
        if (response && response.data) {
          setCategory(response.data)
        }
      } catch (error) {
        console.error("Error fetching incident category:", error)
      }
    }

    if (id) {
      fetchCategory()
    }
  }, [id])

  if (!category) {
    return <p>Loading...</p>
  }

  const handleBack = () => {
    navigate("/incidentcategory")
  }

  const aliases = {
    id: "ID",
    cid: "Category ID",
    name: "Name",
    description: "Description",
    imageUrl: "Image",
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs
            title="Incident Category"
            breadcrumbItem="Incident Category Details"
          />
          <Button
            type="button"
            color="primary"
            className="btn"
            onClick={handleBack}
          >
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        <Row className="justify-content-center">
          <Col lg="12">
            <Card>
              <CardBody>
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category &&
                      Object.entries(category)
                        .filter(([key]) => key !== "deleted")
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>
                              <strong>{aliases[key] || key}</strong>
                            </td>
                            <td>
                              {typeof value === "object"
                                ? JSON.stringify(value)
                                : value}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CategoryDetails
