import React, { useState, useRef, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap"
import { createIncidentCategory, uploadMedia } from "api/categories"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"

const CreateIncidentCategories = () => {
  document.title = "Incident Categories | CA Portal"

  const navigate = useNavigate()

  const [formData, setFormData] = useState({})
  const [file, setFile] = useState(null)
  const [imageUrl, setImageUrl] = useState("")
  const inputRef = useRef(null)
  const fileInputRef = useRef(null)

  const validationSchema = Yup.object({
    name: Yup.string().required("Please Enter Incident Category Name"),
  })

  const formik = useFormik({
    initialValues: {
      name: formData.name || "",
      description: formData.description || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let updatedImageUrl = formData.imageUrl || imageUrl

        if (file) {
          const mediaResponse = await uploadMedia(file)
          updatedImageUrl = mediaResponse.data
          console.log("Media uploaded:", mediaResponse.data)
        }

        const incidentCategoryData = {
          ...formData,
          name: values.name,
          description: values.description,
          imageUrl: updatedImageUrl,
        }

        console.log("Submitting data:", incidentCategoryData)
        await createIncidentCategory(incidentCategoryData)

        alert("Incident Category created successfully")
        navigate("/incidentcategory")
      } catch (error) {
        console.error("Error creating incident category:", error)
        alert("Failed to create incident category. Please try again.")
      } finally {
        setSubmitting(false)
      }
    },
  })
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split('/')[0];
      if (fileType === 'image') {
        setFile(file);
        const fileUrl = URL.createObjectURL(file);
        setImageUrl(fileUrl);
      } else {
        alert('Please upload an image file.');
      }
    }
  };
  

  const handleRemoveFile = () => {
    setFile(null)
    setImageUrl("")
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    formik.setFieldValue("mediafile", "")
  }

  const handleInput = (event) => {
    event.target.style.height = "auto"
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [formik.values.description])

  const handleBack = () => {
    navigate("/incidentcategory")
  }

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between mb-3">
            <Breadcrumbs title="Categories" breadcrumbItem="Create Incident Category" />
            <Button type="button" color="primary" className="btn" onClick={handleBack}>
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </div>
          <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter category name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.name && !!formik.errors.name}
              />
              {formik.touched.name && formik.errors.name && (
                <FormFeedback>{formik.errors.name}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="description">Description:</Label>
              <Input
                type="textarea"
                id="description"
                name="description"
                innerRef={inputRef}
                value={formik.values.description}
                onChange={(event) => {
                  formik.handleChange(event)
                  handleInput(event)
                }}
                onBlur={formik.handleBlur}
                invalid={formik.touched.description && !!formik.errors.description}
                style={{ overflow: "hidden", resize: "none" }}
              />
              {formik.touched.description && formik.errors.description && (
                <FormFeedback>{formik.errors.description}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <label className="form-label">
                  Click to Upload Media Here (Optional)
                </label>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    className="form-control flex-grow-1 me-3"
                    name="mediafile"
                    placeholder="Click to Upload Media Here"
                    id="mediafile"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  {imageUrl && (
                    <div className="d-flex align-items-center">
                      <img src={imageUrl} alt="Uploaded" style={{ maxWidth: "100px" }} />
                      <Button color="danger" onClick={handleRemoveFile} className="ms-3">
                        Remove
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>
            <Button type="submit" color="primary" className="mt-3">
              Create Category
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default CreateIncidentCategories
