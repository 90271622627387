import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  Alert,
  FormFeedback,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createSurveyTitle } from "../../api/survey";

const Survey = () => {
  document.title = "Create Surveys";

  const [surveyCreated, setSurveyCreated] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (surveyCreated) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [surveyCreated]);

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    type: "",
    options: [""],
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formValues.type !== "Open" && formValues.options.length < 2) {
      setError("At least two options are required");
      return;
    }

    try {
      const surveyData = {
        title: formValues.title,
        description: formValues.description,
        type: formValues.type,
        options: formValues.type === "Open" ? [] : formValues.options.map(title => ({ content: title })),
      };

      const topicResponse = await createSurveyTitle(surveyData);

      setSurveyCreated(true);
      setSurveyData({
        title: formValues.title,
        description: formValues.description,
        options: surveyData.options,
      });

      setFormValues({
        title: "",
        description: "",
        type: "",
        options: [""],
      });
      setError(null);
      navigate('/surveys')
    } catch (error) {
      console.error("Error creating survey:", error);
      setError("Failed to create survey. Please try again.");
    }
  };

  const handleAddOption = () => {
    setFormValues({
      ...formValues,
      options: [...formValues.options, ""],
    });
  };

  const handleDeleteOption = index => {
    const updatedOptions = [...formValues.options];
    updatedOptions.splice(index, 1);
    setFormValues({
      ...formValues,
      options: updatedOptions,
    });
  };

  const handleOptionTitleChange = (index, value) => {
    const updatedOptions = [...formValues.options];
    updatedOptions[index] = value;
    setFormValues({
      ...formValues,
      options: updatedOptions,
    });
  };

  const handleInput = event => {
    event.target.style.height = "auto";
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [formValues.description]);

  const handleBack = () => {
    navigate("/surveys");
  };

  const handleCreateAnother = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between mb-3">
              <Breadcrumbs title="Surveys" breadcrumbItem="Create Survey" />
              <Button type="button" color="primary" onClick={handleBack}>
                <i className="bx bx-arrow-back"></i> Back
              </Button>
            </div>
            {!surveyCreated ? (
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="title">Survey Title:</Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={formValues.title}
                    onChange={e => setFormValues({ ...formValues, title: e.target.value })}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Survey Description:</Label>
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    innerRef={inputRef}
                    value={formValues.description}
                    onChange={e => {
                      setFormValues({ ...formValues, description: e.target.value });
                      handleInput(e);
                    }}
                    style={{ overflow: "hidden", resize: "none" }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="type">Survey Type:</Label>
                  <Input
                    type="select"
                    id="type"
                    name="type"
                    value={formValues.type}
                    onChange={e => setFormValues({ ...formValues, type: e.target.value })}
                    required
                  >
                    <option value="">Select Survey Type</option>
                    <option value="Open">Open</option>
                    <option value="Multiple">Multiple Choice</option>
                    <option value="Single">Single Choice</option>
                  </Input>
                </FormGroup>

                {formValues.type && formValues.type !== "Open" && (
                  <FormGroup>
                    {formValues.options.map((title, index) => (
                      <div key={index} className="mt-3">
                        <Input
                          type="text"
                          value={title}
                          onChange={e => handleOptionTitleChange(index, e.target.value)}
                          required
                        />
                        <Button
                          color="danger"
                          onClick={() => handleDeleteOption(index)}
                          className="mt-2"
                        >
                          Delete Option
                        </Button>
                      </div>
                    ))}
                    <Button
                      color="success"
                      type="button"
                      onClick={handleAddOption}
                      className="mt-3"
                    >
                      Add Option
                    </Button>
                  </FormGroup>
                )}

                {error && <FormFeedback className="d-block">{error}</FormFeedback>}

                {/* Create survey button */}
                <div className="text-center mt-3">
                  <Button
                    color="primary"
                    type="submit"
                  >
                    Create Survey
                  </Button>
                </div>
              </Form>
            ) : (
              <div className="mt-3 mb-3">
                {error && <Alert color="danger">{error}</Alert>}
                <h2>Survey Created Successfully</h2>
                <p>
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      marginRight: "8px",
                    }}
                  >
                    Title:
                  </span>
                  {surveyData?.title}
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      marginRight: "8px",
                    }}
                  >
                    Description:
                  </span>
                  {surveyData?.description}
                </p>
                {surveyData?.options && surveyData.options.length > 0 && (
                  <>
                    <p
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        marginBottom: "8px",
                      }}
                    >
                      Options:
                    </p>
                    <ul>
                      {surveyData.options.map((option, index) => (
                        <li key={index}>{option.content}</li>
                      ))}
                    </ul>
                  </>
                )}
                <div className="text-center mb-3">
                  <Button color="success" onClick={handleCreateAnother}>
                    Create Another Survey
                  </Button>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Survey;
