import { get, post, put } from './utils';


export async function IncidentReportSummary(data) {
    return post(`/reports/incidents`, data);
}

export async function SurveyReportSummary(data) {
    return post(`/reports/survey`, data)
}

export async function ContentReportSummary(data) {
    return post(`/reports/content`, data)
}