import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// My added pages
import UserDetails from "pages/Users/UserDetails"

// Pages
import IncidentIndex from "pages/Incidents&Reports"
import IncidentDetails from "pages/Incidents&Reports/IncidentDetails"
import ForumIndex from "pages/Forum"
import Feedback from "pages/Survey&Feedback/Feedback"
import Survey from "pages/Survey&Feedback/CreateSurvey"
import Surveys from "pages/Survey&Feedback/SurveyList"
import SurveyDetails from "pages/Survey&Feedback/SurveyDetails"
import ContentList from "pages/Content/ContentList"
import ContentDetails from "pages/Content/ContentDetails"
import EditContent from "pages/Content/EditContent"
import AppFeedback from "pages/AppFeedback";
import Users from "pages/Users";
import AddUsers from "pages/Users/AddUsers"
import SurveyReports from "pages/Reports/SurveyReports"
import IncidentReports from "pages/Reports/IncidentReports"
import ContentReports from "pages/Reports/ContentReports";
import IncidentCategory from "pages/IncidentCategory";
import CategoryDetails from "pages/IncidentCategory/CategoryDetails";
import CreateIncidentCategories from "pages/IncidentCategory/CreateIncidentCategories";
import EditCategory from "pages/IncidentCategory/EditCategory";
import LearningCentre from "pages/LearningCentre";
import ResetPassword from "pages/Authentication/ResetPassword";
import CreateAlertContentCategories from "pages/Content/CreateContentCategory";
import ContentCategoryList from "pages/Content/ContentCategoryList";
import EditUserDetails from "pages/Users/EditUserDetails";
import EditSurvey from "pages/Survey&Feedback/EditSurvey";
import CreateContent from "pages/Content/CreateContent";
import CreateContentCategories from "pages/Content/CreateContentCategory";
import EditContentCategoryDetails from "pages/Content/EditContentCategoryDetails";
import ContentCategoryDetails from "pages/Content/ContentCategoryDetails";
import EducationalContent from "pages/LearningCentre/EducationalContent"
import EditEducationalContent from "pages/LearningCentre/EditEducationalContent"
import VisitReasons from "pages/AppFeedback/VisitReasons"
import AppUsers from "pages/Users/AppUsers"
import AppUserDetails from "pages/Users/AppUserDetails"
import Test from "pages/Authentication/test"
import VerifyOtp from "pages/Authentication/VerifyOtp"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //profile
  { path: "/profile", component: <UserProfile /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  { path: "/incidents", component: <IncidentIndex /> },
  { path: "/incidents/:id", component: <IncidentDetails /> },
  { path: "/contentCreate", component: <CreateContent /> },
  { path: "/content", component: <ContentList /> },
  { path: "/content-details/:id", component: <ContentDetails />},
  { path: "/edit-content/:id", component: <EditContent />},
  { path: "/forum", component: <ForumIndex /> },
  { path: "/survey", component: <Survey /> },
  { path: "feedback", component: <Feedback /> },
  { path: "/surveys", component: <Surveys /> },
  { path: "/editsurvey/:id/edit", component: <EditSurvey />},

  { path: "/survey/:id", component: <SurveyDetails /> },
  { path: "/appfeedback", component: <AppFeedback />},
  { path: "/visitreasons", component: <VisitReasons />},
  { path: "/users", component: <Users />} ,
  { path: "adduser", component: <AddUsers />},
  { path: "/appusers", component: <AppUsers />},
  { path: "/users/:id", component: <UserDetails/>} ,
  { path: "/appuser/:id", component: <AppUserDetails />},
  { path: "/edit-user/:id", component: <EditUserDetails />},
  { path: "/survey-reports", component: <SurveyReports/>},
  { path: "/incident-reports", component: <IncidentReports/>},
  { path: "/content-reports", component: <ContentReports/>},
  { path: "/incidentcategory", component: <IncidentCategory/>},
  { path: "incidentcategory/:id/edit", component: <IncidentCategory /> },
  { path: "/categorydetails/:id", component: <CategoryDetails />},
  { path: "/categorydetails/:id/edit", component: <EditCategory />},
  { path: "createincidentcategories",component: <CreateIncidentCategories/>},
  { path: "/editcategory/:id/edit", component: <EditCategory />},
  { path: "learningcentre", component: <LearningCentre/>},
  
  { path: "createalertcategory", component: <CreateAlertContentCategories/>},
  { path: "contentcategorylist", component: <ContentCategoryList/>}, 
  { path: "/createcontentcategory", component: <CreateContentCategories/>},
  { path: "/contentcategorylist/:id/edit", component: <EditContentCategoryDetails/>},
  { path: "/contentcategorydetails/:id", component: <ContentCategoryDetails />},
  { path: "/educationalcontent", component: <EducationalContent />},
  { path: "/editeducationalcontent/:id", component: <EditEducationalContent />},

  
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password", component: <ResetPassword />},
  { path: "/verify-otp", component: <VerifyOtp />}

]

export { authProtectedRoutes, publicRoutes }
