import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Card,
  CardBody,
  Col,
  NavItem,
  Row,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap"
import { useParams, useNavigate, Link } from "react-router-dom"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getEducationalContent, deleteContentApi } from "../../api/categories"
import { useRole } from "hooks/useRole"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const LearningCentre = props => {
  document.title = "Learning Center | CA Portal"

  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")
  const [educationalContent, setEducationalContent] = useState([])
  const [filteredContent, setFilteredContent] = useState([])
  const [activeContent, setActiveContent] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchEducationalContent = async () => {
    try {
      const response = await getEducationalContent(0, 10)
      setEducationalContent(response.data)
      setFilteredContent(response.data)
      if (response.data.length > 0) {
        setActiveContent(response.data[0])
      }
    } catch (error) {
      console.error("Error fetching educational content:", error)
    }
  }

  useEffect(() => {
    fetchEducationalContent()
  }, [])

  useEffect(() => {
    const filtered = educationalContent.filter(content =>
      content.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredContent(filtered)
  }, [searchQuery, educationalContent])

  const handleContentClick = content => {
    setActiveContent(content)
    console.log("Active Content:", content)
  }

  useEffect(() => {
    if (activeContent) {
      console.log("Active content changed:", activeContent)
    }
  }, [activeContent])

  const handleTitleHover = event => {
    event.target.style.cursor = "pointer"
    if (!event.target.classList.contains("active-content")) {
      event.target.style.color = "blue"
    }
  }

  const handleTitleLeave = event => {
    if (!event.target.classList.contains("active-content")) {
      event.target.style.color = "initial"
    }
  }

  const formatCreatedAtDate = dateString => {
    const date = new Date(dateString)
    return date.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  }

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleClick = () => {
    navigate("/educationalcontent")
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleDeleteModalToggle = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const handleEdit = () => {
    navigate(`/editeducationalcontent/${activeContent.id}`)
  }

  const handleDelete = async () => {
    try {
      const response = await deleteContentApi(activeContent.id)
      if (response.status === 200) {
        alert("Delete is successful")
        fetchEducationalContent()
        setShowDeleteModal(false)
      }
    } catch (error) {
      alert("Error deleting content:" + error.message)
    }
  }

  const shareUrl = window.location.href
  const shareTitle = activeContent?.title || "Check out this content!"
  const shareDescription = activeContent?.description || ""
  const appLink =
    "Get more information about this from our app on Playstore: https://play.google.com/store/apps/details?id=cak"
  const shareText = `${shareTitle} - ${shareDescription}\n\n${appLink}`
  const mediaShareUrl = activeContent?.imageUrl || shareUrl

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Breadcrumbs title="Learning" breadcrumbItem="Learning Centre" />
          {isAdmin || isSuperAdmin ? (
            <Link to="/educationalcontent">
              <Button type="button" color="primary" className="btn">
                Add Content
              </Button>
            </Link>
          ) : null}
        </div>
        <Row>
          <Col xl={3} lg={4}>
            <Card>
              <CardBody className="p-4">
                <h3 className="mt-3 mb-3">Educational Content</h3>

                <div className="search-box">
                  <p className="text-muted">Search Topics</p>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control rounded bg-light border-light"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
                <hr className="my-4" />
                <div>
                  <ul className="list-unstyled fw-medium">
                    {filteredContent.map(content => (
                      <li
                        key={content.id}
                        onClick={() => handleContentClick(content)}
                        onMouseEnter={handleTitleHover}
                        onMouseLeave={handleTitleLeave}
                      >
                        <span>
                          <p
                            className={
                              activeContent && activeContent.id === content.id
                                ? "active-content"
                                : ""
                            }
                            style={{
                              color:
                                activeContent && activeContent.id === content.id
                                  ? "blue"
                                  : "initial",
                            }}
                          >
                            {content.title}
                          </p>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={9} lg={8}>
            <Card>
              <ul className="nav nav-tabs nav-tabs-custom justify-content-center pt-2">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => toggleTab("1")}
                  >
                    Read All Content Here
                  </NavLink>
                </NavItem>
                {/* <div className="ml-auto d-flex align-items-center">
                  <div className="ml-2">
                    <FacebookShareButton url={mediaShareUrl} quote={shareText}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </div>
                  <div className="ml-2">
                    <TwitterShareButton url={mediaShareUrl} title={shareText}>
                      <XIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                  <div className="ml-2">
                    <WhatsappShareButton url={mediaShareUrl} title={shareText}>
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                </div> */}
              </ul>
              <TabContent className="p-4" activeTab={activeTab}>
                <TabPane tabId="1">
                  {activeContent && (
                    <Row className="justify-content-center">
                      <Col xl={8}>
                        <div className="position-relative">
                          {activeContent.imageUrl && (
                            <>
                              {activeContent.imageUrl.endsWith(".mp4") ? (
                                <video
                                  controls
                                  autoPlay
                                  className="img-thumbnail"
                                >
                                  <source
                                    src={activeContent.imageUrl}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : activeContent.imageUrl.endsWith(".pptx") ||
                                activeContent.imageUrl.endsWith(".docx") ||
                                activeContent.imageUrl.endsWith(".xlsx") ||
                                activeContent.imageUrl.endsWith(".pdf") ? (
                                <a
                                  href={activeContent.imageUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Document
                                </a>
                              ) : (
                                <img
                                  src={activeContent.imageUrl}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              )}
                            </>
                          )}
                        </div>
                        <h5 className="mt-3">
                          {activeContent.title}
                          {isAdmin || isSuperAdmin || isEditor ? (
                            <>
                              <i
                                className="bx bxs-edit-alt text-success"
                                id="edittooltip"
                                onClick={handleEdit}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="edittooltip"
                              >
                                Edit
                              </UncontrolledTooltip>
                            </>
                          ) : null}
                          {isAdmin || isSuperAdmin ? (
                            <>
                              <i
                                className="mdi mdi-delete text-danger font-size-18"
                                id="deletetooltip"
                                onClick={() => setShowDeleteModal(true)}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="deletetooltip"
                              >
                                Delete
                              </UncontrolledTooltip>
                            </>
                          ) : null}
                        </h5>
                        <p className="text-muted mb-0">
                          {formatCreatedAtDate(activeContent.createdAt)}
                        </p>
                        <div className="mt-3">
                          <p>{activeContent.description}</p>
                        </div>
                        <hr className="my-4" />
                      </Col>
                    </Row>
                  )}
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
        <Modal
          size="md"
          isOpen={showDeleteModal}
          toggle={handleDeleteModalToggle}
          centered={true}
        >
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button
                type="button"
                className="btn-close position-absolute end-0 top-0 m-3"
                onClick={handleDeleteModalToggle}
              ></button>
              <div className="avatar-sm mb-4 mx-auto">
                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                  <i className="mdi mdi-trash-can-outline"></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">
                Are you sure you want to permanently delete this item.
              </p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                >
                  Delete Now
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteModalToggle}
                >
                  Close
                </button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </Container>
    </div>
  )
}

export default LearningCentre
