import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addVisitReason,
  getVisitReasons,
  deleteVisitReason,
  getVisitReasonById,
  editVisitReason,
} from "api/users";
import { useRole } from "hooks/useRole";
import { useMutation, useQueryClient } from "react-query";

const VisitReasons = () => {
  document.title = "App Visit Reasons | CA Portal";

  const { isAdmin, isEditor, isSuperAdmin, isAnalyst } = useRole();
  const [visitEntries, setVisitEntries] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editVisitData, setEditVisitData] = useState({
    id: "",
    title: "",
    description: "",
  });

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);

  const toggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const { mutate: mutateVisitReasons } = useMutation(
    ({ page, size }) => getVisitReasons(page, size),
    {
      onSuccess: (res) => {
        setVisitEntries(res.data.content);
        setTotalPages(res.data.totalPages);
      },
      onError: (error) => {
        console.error("Error fetching visit reasons:", error);
      },
    }
  );

  useEffect(() => {
    mutateVisitReasons({ page: currentPage, size: itemsPerPage });
  }, [currentPage]);

  const getCurrentUserId = () => {
    return 1;
  };

  const handleAddVisitReason = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const title = formData.get("title");
      const description = formData.get("description");

      const newVisitReason = {
        title,
        description,
        userId: getCurrentUserId(),
      };

      await addVisitReason(newVisitReason);
      toggleAddModal();
      mutateVisitReasons({ page: currentPage, size: itemsPerPage });
    } catch (error) {
      console.error("Error adding visit reason:", error);
      alert("Error adding visit reason. Please try again")

    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleEditVisitReason = async (id) => {
    try {
      const res = await getVisitReasonById(id);
      setEditVisitData(res.data);
      toggleEditModal();
    } catch (error) {
      console.error("Error fetching visit reason:", error);
    }
  };

  const handleDeleteReason = async (id) => {
    try {
      await deleteVisitReason(id);
      mutateVisitReasons({ page: currentPage, size: itemsPerPage });
    } catch (error) {
      console.error("Error deleting visit reason:", error);
      alert("Error deleting visit reason. Please try again")

    }
  };

  const handleEditReasonSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const title = formData.get("title");
      const description = formData.get("description");

      const updatedVisitReason = {
        id: editVisitData.id,
        title,
        description,
        userId: getCurrentUserId(),
      };

      await editVisitReason(updatedVisitReason);
      toggleEditModal();
      mutateVisitReasons({ page: currentPage, size: itemsPerPage });
    } catch (error) {
      console.error("Error editing visit reason:", error);
      alert("Error editing visit reason. Please try again")

    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumbs title="Feedback" breadcrumbItem="App Visit Reasons" />
          {(isAdmin || isSuperAdmin || isEditor) && (
            <Button color="primary" onClick={toggleAddModal}>
              Add Visit Reason
            </Button>
          )}
        </div>

        <Table striped bordered>
          <thead>
            <tr>
              <th>#Reason ID</th>
              <th>Title</th>
              <th>Description</th>
              {(isAdmin || isSuperAdmin || isEditor) && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {visitEntries.map((reason) => (
              <tr key={reason.id}>
                <td>{reason.id}</td>
                <td>{reason.title}</td>
                <td>{reason.description}</td>
                {(isAdmin || isSuperAdmin || isEditor) && (
                  <td>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      {(isAdmin || isSuperAdmin || isEditor) && (
                        <>
                          <i
                            className="bx bxs-edit-alt text-success font-size-18"
                            id={`edittooltip-${reason.id}`}
                            onClick={() => handleEditVisitReason(reason.id)}
                            style={{ cursor: "pointer" }}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`edittooltip-${reason.id}`}
                          >
                            Edit
                          </UncontrolledTooltip>
                        </>
                      )}
                      {(isAdmin || isSuperAdmin) && (
                        <>
                          <i
                            className="mdi mdi-delete text-danger font-size-18"
                            id={`deletetooltip-${reason.id}`}
                            onClick={() => handleDeleteReason(reason.id)}
                            style={{ cursor: "pointer" }}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`deletetooltip-${reason.id}`}
                          >
                            Delete
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Pagination>
            {[...Array(totalPages).keys()].map((page) => (
              <PaginationItem key={page} active={currentPage === page}>
                <PaginationLink
                  onClick={() => handlePageClick(page)}
                  style={{
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {page + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          </Pagination>
        </div>

        <Modal isOpen={addModalOpen} toggle={toggleAddModal}>
          <ModalHeader toggle={toggleAddModal}>Add Visit Reason</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleAddVisitReason}>
              <FormGroup>
                <Label for="title">Visit Reason</Label>
                <Input type="text" id="title" name="title" required />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input type="text" id="description" name="description" />
              </FormGroup>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </Form>
          </ModalBody>
        </Modal>

        <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit Visit Reason</ModalHeader>
          <ModalBody>
            <Form onSubmit={handleEditReasonSubmit}>
              <FormGroup>
                <Label for="title">Visit Reason</Label>
                <Input
                  type="text"
                  id="title"
                  name="title"
                  value={editVisitData.title}
                  onChange={(e) =>
                    setEditVisitData({
                      ...editVisitData,
                      title: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="text"
                  id="description"
                  name="description"
                  value={editVisitData.description}
                  onChange={(e) =>
                    setEditVisitData({
                      ...editVisitData,
                      description: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default VisitReasons;
