import React from "react"
import { Modal, ModalBody, Form, FormGroup, Label, Input, Button, FormFeedback } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { changeUserPassword } from "api/users"

const ChangePasswordModal = ({ isOpen, toggle, userId }) => {
  const initialValuesUpdate = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  }

  const validationSchemaUpdate = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string()
      .required("Please Confirm Password")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  })

  const forMik = useFormik({
    initialValues: initialValuesUpdate,
    validationSchema: validationSchemaUpdate,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const updateData = {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        }

        await changeUserPassword(userId, updateData)
        alert("Password Updated Successfully!")
        toggle() // Close modal after success
      } catch (error) {
        console.error("Error updating password:", error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <Form onSubmit={forMik.handleSubmit}>
          <h2>Change Password</h2>
          <FormGroup>
            <Label for="oldPassword">Old Password</Label>
            <Input
              type="password"
              id="oldPassword"
              name="oldPassword"
              value={forMik.values.oldPassword}
              onChange={forMik.handleChange}
              onBlur={forMik.handleBlur}
              invalid={forMik.touched.oldPassword && forMik.errors.oldPassword}
            />
            {forMik.touched.oldPassword && forMik.errors.oldPassword && (
              <FormFeedback>{forMik.errors.oldPassword}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="newPassword">New Password</Label>
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              value={forMik.values.newPassword}
              onChange={forMik.handleChange}
              onBlur={forMik.handleBlur}
              invalid={forMik.touched.newPassword && forMik.errors.newPassword}
            />
            {forMik.touched.newPassword && forMik.errors.newPassword && (
              <FormFeedback>{forMik.errors.newPassword}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={forMik.values.confirmPassword}
              onChange={forMik.handleChange}
              onBlur={forMik.handleBlur}
              invalid={
                forMik.touched.confirmPassword && forMik.errors.confirmPassword
              }
            />
            {forMik.touched.confirmPassword && forMik.errors.confirmPassword && (
              <FormFeedback>{forMik.errors.confirmPassword}</FormFeedback>
            )}
          </FormGroup>
          <Button type="submit" color="primary" disabled={forMik.isSubmitting}>
            Submit
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ChangePasswordModal
