import { get, post, put, del } from './utils';

export async function getCategoryOptions(page, size) {
    return get(`/incident-categories?page=${page}&size=${size}`);
}

export async function getContent({  pageSize, pageNumber, fromDate, toDate }) {
  return get(`/content?page=${pageNumber}&size=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`);
}

export async function getContentById(id) {
  return get(`/content/${id}`)
}

export async function createContentApi (data) {
    return post(`/content`, data);
}

export async function updateContentApi(data) {
  return put(`/content`, data)
}

export async function deleteContentApi(id) {
  return del(`/content/${id}`)
}

export async function editContentCategoryDetails(id, data) {
  return put(`/content-categories/${id}`, data);
}


export async function publishContent(data) {
  return post(`/content/publish`, data)
}

export async function unpublishContent(data) {
  return post(`/content/unpublish`, data)
}

export async function archiveContent(data) {
  return post(`/content/archive`, data)
}

const token = localStorage.getItem("sessionToken")
export async function uploadMedia(file) {
    if (!token) {
      throw new Error('Authorization token is missing');
    }
  
    const url = `${process.env.REACT_APP_SERVER_URL}/resource/image`;
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return response.json();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
  

export async function getIncidentCategories (page, size) {
  return get(`/incident-categories?page=${page}&size=${size}`);
}

export async function getIncidentCategoryById(id) {
  return get(`/incident-categories/${id}`);
}

export async function  createIncidentCategory(data) {
  return post('/incident-categories', data);
}

export async function deleteIncidentCategoryApi(id, data) {
  return del(`/incident-categories/${id}`, data)
}


export async function  updateCategory(id, data) {
  return put(`/incident-categories/${id}`, data);
}


export async function  getTopics(data) {
  return get('/content', data);
}

export async function createContentCategory(data) {
  return post('/content-categories', data);
}


export async function getContentOptions(page, size) {
  return get(`/content-categories?page=${page}&size=${size}`);
}

export async function deleteContentCategoryApi(id, data) {
  return del(`/content-categories/${id}`, data)
}

export async function createContent(data) {
  return get('/content', data);
}

export async function getContentCategoryById(id) {
  return get(`/content-categories/${id}`)
}

export async function createalertcontentcategories(data) {
  return get('/content-categories', data);
}

export async function getTopicsContent(data) {
  return get('/content', data);
}


export async function getContentCategoryDetailsById(id) {
  return get(`/content-categories/${id}`);
}

export async function getEducationalContent(page, size) {
  return get(`/content/education?page=${page}&size=${size}`);
}

export async function createEducationalContent(data) {
  return post('/content', data);
}
