import React, { useState, useEffect, useMemo } from "react"
import { Container, Table, Button, Col, Row, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useMutation } from "react-query"
import TableContainer from "../../components/Common/TableContainer"
import { useNavigate } from "react-router-dom"
import { getContent, deleteContentApi } from "api/categories"
import { exportExcel } from "api/crud"
import { ContentReportSummary } from "api/reports"
import DatePicker from "react-datepicker"
import { useRole } from "hooks/useRole"
import ContentChart from "./ContentChart"
import ContentDonutChart from "./ContentDonutChart"

const ContentList = () => {
  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()
  const navigate = useNavigate()

  React.useEffect(() => {
    document.title = "Content | CA Portal"
  }, [])

  // const [pagination, setPagination] = useState({
  //   pageSize: 100,
  //   pageNumber: 0,
  // })
  const [contentData, setContentData] = useState([])
  const [contentReportData, setContentReportData] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [otherParams, setOtherParams] = useState({
    searchColumns: [],
    pageSize: 100,
    pageNumber: 0,
    sortBy: "string",
    ascendingSort: true,
  })

  useEffect(() => {
    const currentDate = new Date()
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1)
    const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31)
    const initialStartDate = formatDate(firstDayOfYear)
    const initialEndDate = formatDate(lastDayOfYear)

    setStartDate(firstDayOfYear)
    setEndDate(lastDayOfYear)

    fetchData({
      fromDate: initialStartDate,
      toDate: initialEndDate,
      ...otherParams,
    })
    fetchContentData({
      fromDate: initialStartDate,
      toDate: initialEndDate,
      ...otherParams,
    })
  }, [])

  const fetchContentData = async params => {
    try {
      const contentRes = await getContent(params)

      const filteredData = contentRes.data.filter(
        item => item.contentCategoryId !== 5
      )
      setContentData(filteredData)
      console.log(filteredData)
    } catch (error) {
      console.error("Error fetching content data:", error)
    }
  }

  const fetchData = async params => {
    try {
      const reportRes = await ContentReportSummary({
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
        ...params,
      })
      setContentReportData(reportRes.data)
      console.log(reportRes.data)
    } catch (error) {
      console.error("Error fetching report data:", error)
    }
  }

  const handleApply = () => {
    const params = {
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
      ...otherParams,
    }
    fetchData(params)
    fetchContentData(params)
  }

  const formatDate = date => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return ""
    }
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const handleExport = async () => {
    try {
      const params = {
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
        ...otherParams,
      }
      await exportExcel("content", params)
    } catch (error) {
      console.error("Error exporting content:", error)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "TITLE",
        accessor: "title",
      },
      {
        Header: "STATUS",
        accessor: "status",
      },
      {
        Header: "TARGET GROUP",
        accessor: "targetGroup",
      },
      {
        Header: "CONTENT CATEGORY",
        accessor: "contentCategoryName",
      },
      {
        Header: "DATE CREATED",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
          return formattedDate
        },
      },
    ],
    []
  )

  const handleViewDetails = record => {
    navigate(`/content-details/${record.original.id}`)
  }

  const handleEdit = record => {
    navigate(`/edit-content/${record.original.id}`)
  }

  const handleCreate = () => {
    navigate("/contentCreate")
  }

  const handleDelete = async id => {
    try {
      await deleteContentApi(id)
      fetchContentData({
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate),
        ...otherParams,
      })
    } catch (error) {
      alert("Error deleting content:" + error.message)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Content" breadcrumbItem="View Content" />
        <Row className="mb-3">
          <Col md={3}>
            <div className="d-flex flex-column align-items-center">
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                className="form-control"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="d-flex flex-column align-items-center">
              <label className="form-label">End Date</label>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                className="form-control"
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="d-flex flex-column align-items-center mt-3">
              <Button color="primary" onClick={handleApply}>
                Apply Filter
              </Button>
            </div>
          </Col>
          {(isAdmin || isSuperAdmin || isAnalyst) && (
            <Col md={3}>
              <div className="d-flex flex-column align-items-center mt-3">
                <Button color="success" onClick={handleExport}>
                  Export Content
                </Button>
              </div>
            </Col>
          )}
        </Row>
        {(isAdmin || isSuperAdmin || isAnalyst) && (
          <Row className="mb-4">
            <Col md={6}>
              <Card>
                <CardBody>
                  {contentReportData && (
                    <ContentChart
                      dataColors='["--bs-success"]'
                      data={contentReportData}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <CardBody>
                  {contentReportData && (
                    <ContentDonutChart
                      dataColors='["--bs-warning","--bs-secondary", "--bs-primary", "--bs-danger", "--bs-success"]'
                      data={contentReportData}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      
        <TableContainer
          showView
          showEdit={isAdmin || isSuperAdmin || isEditor}
          showDelete={isAdmin || isSuperAdmin}
          hasCreate={isAdmin || isSuperAdmin}
          columns={columns}
          data={contentData || []}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          isPagination={true}
          iscustomPageSizeOptions={true}
          addText="Add New Content"
          tableClass="align-middle table-nowrap table-check table"
          theadClass="table-light"
          paginationDiv="col-12"
          pagination="justify-content-left pagination pagination-rounded"
          handleView={handleViewDetails}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          fetchData={handleApply}
        />
      </Container>
    </div>
  )
}

export default ContentList
