import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { confirmOTP } from "api/users"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

const VerifyOtp = props => {
  //meta title
  document.title = "Verify OTP | CA Admin Portal"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const storedEmail = localStorage.getItem("email")
  const storedMobile = localStorage.getItem("mobile")

  const validation = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Please Enter Code"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let response;
        if (storedEmail) {
          response = await confirmOTP({ email: storedEmail, code: values.code })
        } else if (storedMobile) {
          response = await confirmOTP({ mobile: storedMobile, code: values.code })
        }
  
        // Retrieve the temporary token
        const tempToken = localStorage.getItem("tempToken")
  
        // Set session token and roles in local storage
        localStorage.setItem("sessionToken", tempToken)
     
  
        navigate("/dashboard")
      } catch (error) {
        console.log("Error", error)
        alert("Invalid code. Please try again")
      }
      setSubmitting(false)
    },
  })
  
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5>Enter Code Received.</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Enter Code</Label>
                        <Input
                          name="code"
                          value={validation.values.code || ""}
                          type="text"
                          placeholder="Enter Code"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.code &&
                            validation.errors.code
                              ? true
                              : false
                          }
                        />
                        {validation.touched.code &&
                        validation.errors.code ? (
                          <FormFeedback type="invalid">
                            {validation.errors.code}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Verify OTP
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} CA.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(VerifyOtp)
