import React, { useState, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import {
  getContentById,
  updateContentApi,
  uploadMedia,
  getContentOptions,
} from "api/categories"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"

const EditContent = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({})
  const [file, setFile] = useState(null)
  const [imageUrl, setImageUrl] = useState("")
  const [contentOptions, setContentOptions] = useState([])
  const inputRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchContentDetails = async () => {
      try {
        const response = await getContentById(id)
        setFormData(response.data)
        setImageUrl(response.data.imageUrl)
      } catch (error) {
        console.error("Error fetching content details:", error)
      }
    }

    const fetchContentOptions = async () => {
      try {
        const response = await getContentOptions(0, 10)
        const filteredData = response.data.filter(item => item.id !== 5)
        setContentOptions(filteredData)
      } catch (error) {
        console.error("Error fetching content options:", error)
      }
    }

    fetchContentDetails()
    fetchContentOptions()
  }, [id])

  const validationSchema = Yup.object({
    title: Yup.string().required("Please Enter Content Title"),
    selectedContent: Yup.string().required("Please Select Content Type"),
    targetGroup: Yup.string().required("Please Select Target Group"),
  })

  const formik = useFormik({
    initialValues: {
      title: formData.title || "",
      selectedContent: formData.contentCategoryId || "",
      description: formData.description || "",
      targetGroup: formData.targetGroup || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let updatedImageUrl = formData.imageUrl || imageUrl

        if (file) {
          const mediaResponse = await uploadMedia(file)
          updatedImageUrl = mediaResponse.data
        }

        const contentData = {
          ...formData,
          title: values.title,
          description: values.description,
          contentCategoryId: values.selectedContent,
          targetGroup: values.targetGroup,
          imageUrl: updatedImageUrl,
        }

        await updateContentApi(contentData)

        alert("Content updated successfully")
        navigate("/content")
      } catch (error) {
        console.error("Error updating content:", error)
        alert("Failed to update content. Please try again.")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      setFile(file)
      const fileUrl = URL.createObjectURL(file)
      setImageUrl(fileUrl)
    } else {
      setImageUrl(formData.imageUrl)
    }
  }

  const getFileType = url => {
    if (!url) return ""

    const extension = url.split(".").pop().toLowerCase()
    if (extension === "mp4") {
      return "video"
    } else if (["pptx", "docx", "pdf"].includes(extension)) {
      return "document"
    } else {
      return "image"
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    setImageUrl("")
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    formik.setFieldValue("mediafile", "")
  }

  const handleInput = event => {
    event.target.style.height = "auto"
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [formik.values.description])

  const handleCancel = () => {
    navigate("/content")
  }

  const handleBack = () => {
    navigate("/content")
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs title="Content" breadcrumbItem="Edit Content" />
          <Button
            type="button"
            color="primary"
            className="btn"
            onClick={handleBack}
          >
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Col>
                  <Card style={{ borderRadius: "10px", marginTop: "10px" }}>
                    <CardBody className="p-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={formik.handleSubmit}
                      >
                        <FormGroup>
                          <Label for="title">Title</Label>
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            className="form-control"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.title && !!formik.errors.title
                            }
                          />
                          {formik.touched.title && formik.errors.title && (
                            <FormFeedback>{formik.errors.title}</FormFeedback>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="selectedContent">Select Content Category</Label>
                          <Input
                            type="select"
                            name="selectedContent"
                            id="selectedContent"
                            value={formik.values.selectedContent}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.selectedContent &&
                              !!formik.errors.selectedContent
                            }
                          >
                            <option value="">Select Content Category</option>
                            {contentOptions.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Input>
                          {formik.touched.selectedContent &&
                            formik.errors.selectedContent && (
                              <FormFeedback>
                                {formik.errors.selectedContent}
                              </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="targetGroup">Target Group</Label>
                          <Input
                            type="select"
                            name="targetGroup"
                            id="targetGroup"
                            className="form-control"
                            value={formik.values.targetGroup}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.targetGroup &&
                              !!formik.errors.targetGroup
                            }
                          >
                            <option value="">Select Target Group</option>
                            <option value="General">General</option>
                            <option value="Children">Children</option>
                            <option value="Elderly">Elderly</option>
                          </Input>

                          {formik.touched.targetGroup &&
                            formik.errors.targetGroup && (
                              <FormFeedback>
                                {formik.errors.targetGroup}
                              </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <Label for="description">Description:</Label>
                          <Input
                            type="textarea"
                            id="description"
                            name="description"
                            innerRef={inputRef}
                            value={formik.values.description}
                            onChange={event => {
                              formik.handleChange(event)
                              handleInput(event)
                            }}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.description &&
                              !!formik.errors.description
                            }
                            style={{ overflow: "hidden", resize: "none" }}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <FormFeedback>
                                {formik.errors.description}
                              </FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <div className="mb-3">
                            <label className="form-label">
                              Click to Upload Media Here (Optional)
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type="file"
                                className="form-control flex-grow-1 me-3"
                                name="mediafile"
                                placeholder="Click to Upload Media Here"
                                id="mediafile"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                              />
                              {imageUrl && (
                                <>
                                  {getFileType(imageUrl) === "image" && (
                                    <img
                                      src={imageUrl}
                                      alt="Uploaded"
                                      style={{ maxWidth: "100px" }}
                                    />
                                  )}
                                  {getFileType(imageUrl) === "video" && (
                                    <video
                                      controls
                                      style={{ maxWidth: "100px" }}
                                    >
                                      <source src={imageUrl} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}
                                  {getFileType(imageUrl) === "document" && (
                                    <a
                                      href={imageUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Document
                                    </a>
                                  )}
                                  <Button
                                    color="danger"
                                    onClick={handleRemoveFile}
                                    className="ms-3"
                                  >
                                    Remove
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </FormGroup>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            color="success"
                            style={{ marginRight: "10px" }}
                            size="lg"
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            size="lg"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EditContent
