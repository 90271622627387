import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  UncontrolledAlert,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "hooks/useAuth"
import { useRole } from "hooks/useRole"

import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"
import { loginUser, socialLogin } from "../../store/actions"
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

const Login = props => {
  document.title = "Login | CA Portal"

  const dispatch = useDispatch()
  const navigate = useNavigate()


  const { isAuthenticated, login, loginError, loading } = useAuth()

  const validationSchema = Yup.object({
    emailOrPhone: Yup.string().required(
      "Please Enter Your Email or Mobile Number"
    ),
    password: Yup.string().required("Please Enter Your Password"),
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailOrPhone: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      try {
        let loginData = {
          email: values.emailOrPhone,
          password: values.password,
        }

        await login(loginData)

        navigate("/dashboard")

        // localStorage.setItem("session")
      } catch (error) {
        console.error("Error during login:", error)
      }
    },
  })



  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {/* {loginError && (
                <Alert color="danger">
                  {loginError?.code !== 200
                    ? "Invalid Credentials. Please try again"
                    : loginError?.message}
                </Alert>
              )} */}
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="p-4 text-white">
                        <span className="font-size-14 fw-bolder">
                          CA Admin Portal
                        </span>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="avatar-md profile-user-wid">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          Email or Phone Number
                        </Label>
                        <Input
                          name="emailOrPhone"
                          className="form-control"
                          placeholder="Enter Email or Phone Number"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.emailOrPhone}
                          invalid={
                            validation.touched.emailOrPhone &&
                            validation.errors.emailOrPhone
                          }
                        />
                        {validation.touched.emailOrPhone &&
                          validation.errors.emailOrPhone && (
                            <FormFeedback type="invalid">
                              {validation.errors.emailOrPhone}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
