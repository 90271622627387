import { ROLES } from "configs/constants";

export const useRole = () => {
    const role = localStorage.getItem('caRole');
    return {
        isAdmin: role === ROLES.Admin,
        isEditor: role === ROLES.Editor,
        isAnalyst: role === ROLES.Analyst,
        isSuperAdmin: role === ROLES.SuperAdmin
    }
}