import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Container, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import SplineArea from "./SplineArea";
import BarChart from "./barChart";
import DonutChart from "./donutchart";
import ChartApex from "./chartapex";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { IncidentReportSummary, SurveyReportSummary, ContentReportSummary } from "api/reports";
import DatePicker from "react-datepicker";

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "Dashboard | CA Portal";
  }, []);

  const [incidentData, setIncidentData] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1)); 
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 31)); 
  const [otherParams, setOtherParams] = useState({
    searchColumns: [],
    pageSize: 100,
    pageNumber: 0,
    sortBy: "string",
    ascendingSort: true,
  });

  useEffect(() => {
    const initialStartDate = formatDate(startDate);
    const initialEndDate = formatDate(endDate);

    fetchIncidentData({ fromDate: initialStartDate, toDate: initialEndDate, ...otherParams });
    fetchSurveyData({ fromDate: initialStartDate, toDate: initialEndDate, ...otherParams });
    fetchContentData({ fromDate: initialStartDate, toDate: initialEndDate, ...otherParams });
  }, []);

  const fetchIncidentData = async (params) => {
    try {
      const incidentRes = await IncidentReportSummary(params);
      setIncidentData(incidentRes.data);
    } catch (error) {
      console.error("Error fetching incident data:", error);
    }
  };

  const fetchSurveyData = async (params) => {
    try {
      const surveyRes = await SurveyReportSummary(params);
      setSurveyData(surveyRes.data);
    } catch (error) {
      console.error("Error fetching survey data:", error);
    }
  };

  const fetchContentData = async (params) => {
    try {
      const contentRes = await ContentReportSummary(params);
      setContentData(contentRes.data);
    } catch (error) {
      console.error("Error fetching content data:", error);
    }
  };

  const handleApply = () => {
    const formattedParams = {
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
      ...otherParams,
    };
    fetchIncidentData(formattedParams);
    fetchSurveyData(formattedParams);
    fetchContentData(formattedParams);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return "";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />
          <Row className="mb-3">
            <Col md={3}>
              <div className="d-flex flex-column align-items-center">
                <label className="form-label">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  className="form-control"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex flex-column align-items-center">
                <label className="form-label">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  className="form-control"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex flex-column align-items-center mt-3">
                <Button color="primary" onClick={handleApply}>
                  Apply Filter
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={8}>
              <Card className="h-100">
                <CardBody>
                  <CardTitle className="mb-4">Incidents Report</CardTitle>
                  <Row>{incidentData && <SplineArea dataColors='["--bs-success", "--bs-primary", "--bs-warning","--bs-secondary"]' data={incidentData} />}</Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100 d-flex flex-column">
                <CardBody className="d-flex flex-column">
                  <CardTitle className="mb-4">Incidents Chart</CardTitle>
                  {incidentData && <DonutChart dataColors='["--bs-warning","--bs-secondary"]' data={incidentData} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
              <Card className="h-100 d-flex flex-column">
                <CardBody className="d-flex flex-column">
                  <CardTitle className="mb-4">Survey Report</CardTitle>
                  {surveyData && <BarChart dataColors='["--bs-success"]' data={surveyData} />}
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="h-100 d-flex flex-column">
                <CardBody className="d-flex flex-column">
                  <CardTitle className="mb-4">Content Report</CardTitle>
                  {contentData && <ChartApex dataColors='["--bs-success"]' data={contentData} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
