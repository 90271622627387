import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// users
import logo from "../../../assets/images/logo.svg";
import ChangePasswordModal from "components/Modals/ChangePasswordModal";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleUpdateModalToggle = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const roleAliases = {
    ROLE_ADMIN: "Admin",
    ROLE_EDITOR: "Editor",
    ROLE_ANALYST: "Analyst",
    ROLE_SUPER_ADMIN: "Super Admin",
  };

  const roleString = localStorage.getItem("caRole");

  const role = roleString ? roleString.replace("ROLE_", "") : "";

  const roleAlias = role ? roleAliases[`ROLE_${role.toUpperCase()}`] : "";

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={logo}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {roleAlias}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => navigate("/profile")}>
            <Link to="/profile" className="dropdown-link">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}
            </Link>
          </DropdownItem>
          <DropdownItem onClick={handleUpdateModalToggle}>
            <i className="bx bx-key font-size-16 align-middle me-1 mt-2" />
            {props.t("Change Password")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      {/* Change Password Modal */}
      <ChangePasswordModal 
        isOpen={showUpdateModal} 
        toggle={handleUpdateModalToggle} 
        userId={localStorage.getItem("appUserId")} 
      />
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
