import React, { useState, useEffect, useRef } from "react"
import {
  FormGroup,
  CardBody,
  Col,
  Container,
  Row,
  Card,
  Label,
  Alert,
  Input,
  Button,
  FormFeedback,
  Form,
} from "reactstrap"
import {
  getContentOptions,
  createContentApi,
  uploadMedia,
} from "api/categories"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"

const CreateContent = () => {
  const navigate = useNavigate()
  const [selectedContent, setSelectedContent] = useState("")
  const [contentOptions, setContentOptions] = useState([])
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [imageUrl, setImageUrl] = useState("")
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const inputRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchContentOptions = async () => {
      try {
        const response = await getContentOptions(0, 10)
        const filteredData = response.data.filter(item => item.id !== 5)

        setContentOptions(filteredData)
      } catch (error) {
        console.error("Error fetching content options:", error)
      }
    }

    fetchContentOptions()
  }, [])

  const validationSchema = Yup.object({
    title: Yup.string().required("Please Enter Content Title"),
    selectedContent: Yup.string().required("Please Select Content Type"),
    targetGroup: Yup.string().required("Please Select Target Group")
  })

  const formik = useFormik({
    initialValues: {
      title: "",
      selectedContent: "",
      description: "",
      targetGroup: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        let imageUrl = ""

        if (selectedFile) {
          const mediaResponse = await uploadMedia(selectedFile)
          imageUrl = mediaResponse.data
        }

        const contentData = {
          title: values.title,
          description: values.description,
          targetGroup: values.targetGroup,
          contentCategoryId: values.selectedContent,
          imageUrl: imageUrl,
        }

        const response = await createContentApi(contentData)

        alert("Content created successfully")
        console.log("Content created successfully:", response)

        setShowSuccessAlert(true)
        resetForm()
        setSelectedFile(null)
        setImageUrl("")
        navigate("/content")
      } catch (error) {
        console.error("Error creating content:", error)
        alert("Failed to create content. Please try again.")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      setSelectedFile(file) // Update selectedFile state with the file from event
      const fileUrl = URL.createObjectURL(file)
      console.log("File URL:", fileUrl)
      setImageUrl(fileUrl)
    }
  }

  const getFileType = fileUrl => {
    if (!fileUrl) return ""

    const extension = fileUrl.split(".").pop().toLowerCase()
    if (extension === "mp4") {
      return "video"
    } else if (["pptx", "docx", "pdf"].includes(extension)) {
      return "document"
    } else {
      return "image"
    }
  }

  const handleRemoveFile = () => {
    setSelectedFile(null)
    setImageUrl("")
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    formik.setFieldValue("mediafile", "")
  }

  const handleInput = event => {
    event.target.style.height = "auto"
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [formik.values.description])

  const handleCancel = () => {
    navigate("/content")
  }

  const handleBack = () => {
    navigate("/content")
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs title="Content" breadcrumbItem="Create Content" />
          <Button
            type="button"
            color="primary"
            className="btn"
            onClick={handleBack}
          >
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        {showSuccessAlert && (
          <Alert color="success" className="mb-4">
            Content created successfully!
          </Alert>
        )}
        <Row>
          <Col>
            <Card style={{ borderRadius: "10px", marginTop: "10px" }}>
              <CardBody className="p-4">
                <Form
                  className="form-horizontal"
                  onSubmit={formik.handleSubmit}
                >
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      className="form-control"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={formik.touched.title && !!formik.errors.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <FormFeedback>{formik.errors.title}</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="selectedContent">Select Content Category</Label>
                    <Input
                      type="select"
                      name="selectedContent"
                      id="selectedContent"
                      value={formik.values.selectedContent}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.selectedContent &&
                        !!formik.errors.selectedContent
                      }
                    >
                      <option value="">Select Content Category</option>
                      {contentOptions.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Input>
                    {formik.touched.selectedContent &&
                      formik.errors.selectedContent && (
                        <FormFeedback>
                          {formik.errors.selectedContent}
                        </FormFeedback>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="targetGroup">Target Group</Label>
                    <Input
                      type="select"
                      name="targetGroup"
                      id="targetGroup"
                      className="form-control"
                      value={formik.values.targetGroup}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.targetGroup &&
                        !!formik.errors.targetGroup
                      }
                    >
                    <option value="">Select Target Group</option>
                    <option value="General">General</option>
                    <option value="Children">Children</option>
                    <option value="Elderly">Elderly</option>
                    </Input>

                    {formik.touched.targetGroup &&
                      formik.errors.targetGroup && (
                        <FormFeedback>{formik.errors.targetGroup}</FormFeedback>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description:</Label>
                    <Input
                      type="textarea"
                      id="description"
                      name="description"
                      innerRef={inputRef}
                      value={formik.values.description}
                      onChange={event => {
                        formik.handleChange(event)
                        handleInput(event)
                      }}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched.description &&
                        !!formik.errors.description
                      }
                      style={{ overflow: "hidden", resize: "none" }}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <div className="mb-3">
                      <label className="form-label">
                        Click to Upload Media Here (Optional)
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="file"
                          className="form-control flex-grow-1 me-3"
                          name="mediafile"
                          placeholder="Click to Upload Media Here"
                          id="mediafile"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                          accept=".pdf,.doc,.docx,.ppt,.pptx,image/*,video/*"
                        />
                        {imageUrl && (
                          <div className="d-flex align-items-center">
                            <img
                              src={imageUrl}
                              alt="Uploaded"
                              style={{ maxWidth: "100px" }}
                            />
                            <Button
                              color="danger"
                              onClick={handleRemoveFile}
                              className="ms-3"
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </FormGroup>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      color="success"
                      style={{ marginRight: "10px" }}
                      size="lg"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Save
                    </Button>
                    <Button color="primary" size="lg" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateContent
