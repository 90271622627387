import React, { useState, useEffect } from "react";
import { Container, Table, Button, Modal, ModalHeader, ModalBody, Pagination, PaginationItem, PaginationLink, Transition } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getfeedbacks } from "api/users";
import { useRole } from "hooks/useRole"

const Feedback = () => {
    document.title = "App User Feedback | CA Portal";

    const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole();


    const [feedbackEntries, setFeedbackEntries] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 5;
    const totalPages = Math.ceil(feedbackEntries.length / itemsPerPage);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const userId = getCurrentUserId(); 

                if (userId) {
                    const response = await getfeedbacks(userId);
                    setFeedbackEntries(response.data.content);
                } else {
                    console.error("User ID not available.");
                }
            } catch (error) {
                console.error("Error fetching feedbacks:", error);
            }
        };

        fetchFeedbacks();
    }, []);



    const handleViewFeedback = (feedback) => {
        setSelectedFeedback(feedback);
        setViewModalOpen(true);
    };

    const toggleViewModal = () => {
        setViewModalOpen(!viewModalOpen);
    };



    const getCurrentUserId = () => {
        return 1;
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const paginatedFeedbackEntries = feedbackEntries.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="page-content">
            <Container fluid>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Breadcrumbs title="Feedback" breadcrumbItem="App User Feedback" />
                   
                </div>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Message</th>
                            <th>Rating</th>
                            <th>Feedback Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedFeedbackEntries.map((feedback, index) => (
                            <tr key={feedback.id}>
                                <td>{feedback.id}</td>
                                <td>{feedback.message}</td>
                                <td>{feedback.rating}</td>
                                <td>{new Date(feedback.createdAt).toLocaleString()}</td>
                                <td>
                                    <Button color="success" onClick={() => handleViewFeedback(feedback)}>
                                        View Feedback
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                    <Pagination>
                        {[...Array(totalPages).keys()].map((page) => (
                            <PaginationItem key={page} active={currentPage === page + 1}>
                                <PaginationLink onClick={() => handlePageClick(page + 1)} style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {page + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                    </Pagination>
                </div>

                <Modal isOpen={viewModalOpen} toggle={toggleViewModal}>
                    <ModalHeader toggle={toggleViewModal}>View Feedback</ModalHeader>
                    <ModalBody>
                        {selectedFeedback && (
                            <>
                                <p><strong>Message:</strong> {selectedFeedback.message}</p>
                                <p><strong>Rating:</strong> {selectedFeedback.rating}</p>
                                <p><strong>Feedback Date:</strong> {new Date(selectedFeedback.createdAt).toLocaleString()}</p>
                            </>
                        )}
                    </ModalBody>
                </Modal>

              
            </Container>
        </div>
    );
};

export default Feedback;









