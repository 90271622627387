import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button, Card, CardBody, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getUserDetailsById } from "api/users"
import { useParams, useNavigate } from "react-router-dom"

const AppUserDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserDetailsById(id)
        const formattedData = formatDates(response?.data)

        setUserData(formattedData)
      } catch (error) {
        console.error("Error fetching user details:", error)
      }
    }

    if (id) {
      fetchUserData()
    }
  }, [id])

  const formatDate = date => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    if (!(date instanceof Date) || isNaN(date.getDate())) {
      return ""
    }
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const formatDates = data => {
    const formattedData = { ...data }
    formattedData.dateOfBirth = formatDate(data.dateOfBirth)
    return formattedData
  }

  const handleBack = () => {
    navigate("/appusers")
  }

  const roleAliases = {
    ROLE_USER: "User",
  }

  const otherKeyAliases = {
    id: "ID",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    email: "Email",
    mobile: "Mobile",
    dateOfBirth: "Date of Birth",
    sex: "Sex",
    preferredLanguage: "Preferred Language",
    forumUserId: "Forum User ID",
    profileUrl: " Profile URL",
    roles: "Role",
    joinedForum: "Joined Forum",
  }

  const getRoleAlias = roleName => {
    return roleAliases[roleName] || roleName
  }

  const getKeyAlias = key => {
    return otherKeyAliases[key] || key
  }

  return (
    <div className="page-content">
      <div className="d-flex justify-content-between mb-3">
        <Breadcrumbs title="Users" breadcrumbItem="User Details" />
        <Button
          type="button"
          color="primary"
          className="btn"
          onClick={handleBack}
        >
          <i className="bx bx-arrow-back"></i> Back
        </Button>
      </div>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg="12">
            <Card>
              <CardBody>
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData &&
                      Object.entries(userData)
                        .filter(([key, value]) => value !== null)
                        .filter(
                          ([key]) =>
                            ![
                              "deleted",
                              "userType",
                              "status",
                              "profileId",
                              "profileURL",
                              "isEmailVerified",
                              "isMobileVerified",
                            ].includes(key)
                        )
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>
                              <strong>{otherKeyAliases[key] || key}</strong>
                            </td>
                            <td>
                              {key === "roles"
                                ? value.map(role => (
                                    <span key={role.roleId}>
                                      {getRoleAlias(role.roleName)}
                                    </span>
                                  ))
                                : typeof value === "boolean"
                                ? value.toString()
                                : typeof value === "object"
                                ? JSON.stringify(value)
                                : value}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AppUserDetails
