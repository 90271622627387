import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap"
import { useParams, useNavigate } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  archiveContent,
  getContentById,
  publishContent,
  unpublishContent,
} from "api/categories"
import { useRole } from "hooks/useRole"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const ContentDetails = () => {
  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()

  document.title = "Content | Details"

  const { id } = useParams()
  const navigate = useNavigate()
  const [contentData, setContentData] = useState({})
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [unformattedDate, setUnformattedDate] = useState("")

  useEffect(() => {
    const fetchContentDetails = async () => {
      try {
        const response = await getContentById(id)
        if (response.status === 200) {
          const formattedData = formatDates(response.data)
          setContentData(formattedData)
          setUnformattedDate(response.data.createdAt)
        }
      } catch (error) {
        console.error("Error fetching content details:", error)
      }
    }

    fetchContentDetails()
  }, [id])

  const formatDate = dateString => {
    const date = new Date(dateString)
    return date.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  }

  const formatDates = data => {
    const formattedData = { ...data }
    formattedData.createdAt = formatDate(data.createdAt)
    return formattedData
  }

  const handleDropdownToggle = () => {
    setSettingsMenu(!settingsMenu)
  }

  const handleEditClick = () => {
    navigate(`/edit-content/${id}`)
  }

  const handlePublishClick = async e => {
    e.preventDefault()

    try {
      const response = await publishContent({
        ...contentData,
        createdAt: unformattedDate,
      })
      if (response.status === 200) {
        setContentData(prevData => ({
          ...prevData,
          status: "Published",
        }))
        console.log("Content Published Successfully", response.data)
        alert("Content Published Successfully")
      }
    } catch (error) {
      console.error("Error publishing content:", error)
      alert("Error publishing content. Please try again")
    }
  }

  const handleUnpublishClick = async e => {
    e.preventDefault()

    try {
      const response = await unpublishContent({
        ...contentData,
        createdAt: unformattedDate,
      })
      if (response.status === 200) {
        setContentData(prevData => ({
          ...prevData,
          status: "Unpublished",
        }))
        console.log("Content Unpublished Successfully", response.data)
        alert("Content Unpublished Successfully")
      }
    } catch (error) {
      console.error("Error unpublishing content:", error)
      alert("Error unpublishing content. Please try again")
    }
  }

  const handleArchiveClick = async e => {
    e.preventDefault()

    try {
      const response = await archiveContent({
        ...contentData,
        createdAt: unformattedDate,
      })
      if (response.status === 200) {
        setContentData(prevData => ({
          ...prevData,
          status: "Archived",
        }))
        console.log("Content Archived Successfully", response.data)
        alert("Content Archived Successfully")
      }
    } catch (error) {
      console.error("Error archiving content:", error)
      alert("Error archiving content. Please try again")
    }
  }

  const handleBack = () => {
    navigate("/content")
  }

  const renderMedia = url => {
    if (!url || url.trim() === "") {
      return <span>No media available</span> // or you can render a placeholder image or a default message
    }

    const extension = url.split(".").pop().toLowerCase()
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <img src={url} alt="Preview" style={{ maxWidth: "100px" }} />
      case "mp4":
      case "webm":
        return (
          <video width="320" height="240" controls>
            <source src={url} type={`video/${extension}`} />
            Your browser does not support the video tag.
          </video>
        )
      case "pdf":
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            View PDF
          </a>
        )
      case "doc":
      case "docx":
      case "ppt":
      case "pptx":
      case "xlsx":
      case "csv":
      case "txt":
      case "odp":
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
        )
      default:
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            View File
          </a>
        )
    }
  }

  const labelMappings = {
    title: "Title",
    description: "Description",
    createdAt: "Date Created",
    imageUrl: "Media",
    status: "Status",
    contentCategoryName: "Content Category Name",
    targetGroup: "Target Group"
  }

  const excludeKeys = ["tags", "deleted", "id", "appUserId", "contentCategoryId"]

  const shareUrl = window.location.href
  const shareTitle = contentData?.title || "Check out this content!"
  const shareDescription = contentData?.description || ""
  const appLink =
    "Get more information about this from our app on Playstore: https://play.google.com/store/apps/details?id=cak"
  const shareText = `${shareTitle} - ${shareDescription}\n\n${appLink}`

  const mediaShareUrl = contentData?.imageUrl || shareUrl

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between mb-3">
            <Breadcrumbs title="Content" breadcrumbItem="Content Details" />
            <Button
              type="button"
              color="primary"
              className="btn"
              onClick={handleBack}
            >
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </div>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col
                      xs={12}
                      className="d-flex justify-content-end align-items-center ml-2"
                    >
                      {(isAdmin || isSuperAdmin) && (
                        <>
                          {/* <div className="ml-2">
                            <FacebookShareButton
                              url={mediaShareUrl}
                              quote={shareText}
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </div>
                          <div className="ml-2">
                            <TwitterShareButton
                              url={mediaShareUrl}
                              title={shareText}
                            >
                              <XIcon size={32} round />
                            </TwitterShareButton>
                          </div>
                          <div className="ml-2">
                            <WhatsappShareButton
                              url={mediaShareUrl}
                              title={shareText}
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </div> */}
                          <Dropdown
                            isOpen={settingsMenu}
                            toggle={handleDropdownToggle}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-primary"
                            >
                              <i className="bx bx-align-right" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              {contentData.status !== "Published" && (
                                <DropdownItem onClick={handlePublishClick}>
                                  Publish Content
                                </DropdownItem>
                              )}
                              {contentData.status !== "Unpublished" && (
                                <DropdownItem onClick={handleUnpublishClick}>
                                  Unpublish Content
                                </DropdownItem>
                              )}
                              {contentData.status !== "Archived" && (
                                <DropdownItem onClick={handleArchiveClick}>
                                  Archive Content
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </>
                      )}
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        {Object.entries(contentData).map(([key, value]) => {
                          if (
                            !excludeKeys.includes(key) &&
                            value !== null &&
                            value !== undefined
                          ) {
                            const label = labelMappings[key] || key
                            return (
                              <tr key={key}>
                                <th scope="row">{label}</th>
                                {key === "imageUrl" ? (
                                  <td>{renderMedia(value)}</td>
                                ) : (
                                  <td>{value.toString()}</td>
                                )}
                              </tr>
                            )
                          }
                          return null
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContentDetails
