import { createContext, useReducer } from "react"
import { sendOTP } from "api/users"

const initialState = {
  user: null,
  configs: null,
  collapsed: 1,
  summaryData: {},
  isAuthenticated: !!localStorage.getItem("sessionToken"),
}

async function reducer(state, action) {
  switch (action.type) {
    case "setCollapsed":
      state.collapsed = action?.collapsed
      return state
    case "setSummaryData":
      state.summaryData = {
        ...state.summaryData,
        ...action?.summaryData,
      }
      return state
    case "registerGoogle":
      state.isAuthenticated = true
      localStorage.setItem("sessionToken", action?.token)
      return state
    case "setUser":
      state.user = action.payload
      state.isAuthenticated = true
      return state
    case "register":
      state.loading = true
      return state
    case "registerSuccess":
      state.loading = false
      // state.isAuthenticated = true;
      // localStorage.setItem('sessionToken', action?.payload?.accessToken);
      return state
    case "registerFail":
      state.loading = false
      return state
    case "login":
      state.loading = true
      return state
    case "loginSuccess":
      const { data } = action.payload
      const {
        roles,
        mfaEnabled,
        token,
        id,
        isEmailVerified,
        isMobileVerified,
        email,
        mobile,
      } = data

      if (roles && roles.length > 0) {
        const roleName = roles[0].roleName
        localStorage.setItem("caRole", roleName)
      } else {
        localStorage.removeItem("caRole")
      }

      localStorage.setItem("mfaEnabled", mfaEnabled)
      localStorage.setItem("appUserId", id)
      localStorage.setItem("tempToken", token) // Temporary storage for token
      localStorage.setItem("email", email)
      localStorage.setItem("mobile", mobile)

      const sendOTPToEmail = async email => {
        try {
          await sendOTP({ email })
          console.log("OTP sent to email:", email)
        } catch (error) {
          console.error("Error sending OTP to email:", error)
          alert("Error sending OTP to email. Please try again")
        }
      }

      const sendOTPToMobile = async mobile => {
        try {
          await sendOTP({ mobile })
          console.log("OTP sent to mobile:", mobile)
        } catch (error) {
          console.error("Error sending OTP to mobile:", error)
          alert("Error sending OTP to mobile. Please try again")
        }
      }

      const handleMFA = async () => {
        try {
          if (isEmailVerified && isMobileVerified) {
            console.log(
              "Both email and mobile are verified. Sending OTP to email:",
              email
            )
            await sendOTPToEmail(email)
          } else if (isEmailVerified) {
            console.log("Email is verified. Sending OTP to:", email)
            await sendOTPToEmail(email)
          } else if (isMobileVerified) {
            console.log("Mobile is verified. Sending OTP to:", mobile)
            await sendOTPToMobile(mobile)
          } else {
            console.log("Neither email nor mobile is verified.")
            return
          }

          // Redirect to OTP verification page
          window.location.href = "/verify-otp"
          return { ...state, isVerifyingOtp: true }
        } catch (error) {
          console.error("Error sending OTP:", error)
          alert("Error sending OTP. Please try again.")
          return { ...state, isVerifyingOtp: false }
        }
      }

      if (mfaEnabled) {
        // Handle MFA asynchronously
        handleMFA()
        return { ...state, isAuthenticated: true }
      } else {
        localStorage.setItem("sessionToken", token)
        return { ...state, isAuthenticated: true }
      }

    case "loginFail":

      console.log("Login Failed")
      alert("Login Failed. Please try again")
      state.loading = false
      return state
    case "currentUserSuccess":
      state.loading = false
      localStorage.setItem("user", JSON.stringify(action?.payload))
      state.user = action?.payload
      return state
    case "currentUserFail":
      state.loading = false
      return state
    case "logout":
      console.log("TO LOG OUT")
      state.user = null
      state.isAuthenticated = false
      localStorage.removeItem("sessionToken")
      localStorage.removeItem("appUserId")
      localStorage.removeItem("caRole")
      localStorage.removeItem("mfaEnabled")
      localStorage.removeItem("tempToken")
      localStorage.removeItem("email")
      localStorage.removeItem("mobile")

      return state
    case "setConfigs":
      state.configs = action.configs
      return state
    default:
      throw new Error()
  }
}

export const useStore = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return [state, dispatch]
}

export const StoreContext = createContext({
  state: {},
  dispatch: () => {},
})
