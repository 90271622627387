import React, { useState, useEffect } from "react"
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { adminCreateUsers, getRoles } from "api/users"
import { useNavigate } from "react-router-dom"

const AddUsers = () => {
  const navigate = useNavigate()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    document.title = "Add Users | CA Portal"
  }, [])

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getRoles(0, 10)
        console.log(response.data)

        const filteredData = response.data.filter(
          item =>
            item.roleName !== "ROLE_USER" &&
            item.roleName !== "ROLE_SUPER_ADMIN"
        )
        setRoles(filteredData)
      } catch (error) {
        console.error("Error fetching roles:", error)
      }
    }

    fetchRoles()
  }, [])

  // Aliases for roles
  const roleAliases = {
    ROLE_SUPER_ADMIN: "Super Admin",
    ROLE_ADMIN: "Admin",
    ROLE_ANALYST: "Analyst",
    ROLE_EDITOR: "Editor",
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please Enter First Name"),
    lastName: Yup.string().required("Please Enter Last Name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[\W_]/, "Password must contain at least one special character")
      .required("Password is required"),
    preferredLanguage: Yup.string().required("Preferred Language is required"),
    sex: Yup.string().required("Sex is required"),
    mobile: Yup.string()
      .matches(
        /^(\+254|0)(7[0-9]{8})$/,
        "Please enter a valid phone number starting with 07 or +254"
      )
      .required("Mobile number is required"),
    roleId: Yup.string().required("Role is required"),
    dateOfBirth: Yup.date().required("Date of Birth is required").nullable(),
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      preferredLanguage: "",
      sex: "",
      dateOfBirth: null,
      mobile: "",
      roleId: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const selectedRole = roles.find(
          role => role.subRoleId === parseInt(values.roleId)
        )
        if (selectedRole) {
          values.role = selectedRole.roleName
        }
        if (values.dateOfBirth) {
          values.dateOfBirth = new Date(values.dateOfBirth).toISOString()
        }
        console.log("Form Values:", values)
        const response = await adminCreateUsers(values)
        if (response.status === 201) {
          console.log("User created successfully!")
          navigate("/users")
        } else {
          console.error("Failed to create user:", response)
        }
      } catch (error) {
        console.error("Error creating user:", error)
        alert("Error creating user. Please try again")
      }
    },
  })

  const handleBack = () => {
    navigate("/users")
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs title="Users" breadcrumbItem="Add User" />
          <Button
            type="button"
            color="primary"
            className="btn"
            onClick={handleBack}
          >
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="text-danger">{formik.errors.firstName}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="middleName">Middle Name</Label>
                <Input
                  type="text"
                  id="middleName"
                  name="middleName"
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.middleName && (
                  <div className="text-danger">{formik.errors.middleName}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="text-danger">{formik.errors.lastName}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-danger">{formik.errors.email}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="text-danger">{formik.errors.password}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="preferredLanguage">Preferred Language</Label>
                <Input
                  type="select"
                  id="preferredLanguage"
                  name="preferredLanguage"
                  value={formik.values.preferredLanguage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Preferred Language</option>
                  <option value="ENGLISH">English</option>
                  <option value="KISWAHILI">Kiswahili</option>
                </Input>
                {formik.touched.preferredLanguage &&
                  formik.errors.preferredLanguage && (
                    <div className="text-danger">
                      {formik.errors.preferredLanguage}
                    </div>
                  )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="sex">Sex</Label>
                <Input
                  type="select"
                  id="sex"
                  name="sex"
                  value={formik.values.sex}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Sex</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </Input>
                {formik.touched.sex && formik.errors.sex && (
                  <div className="text-danger">{formik.errors.sex}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="mobile">Phone Number</Label>
                <Input
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className="text-danger">{formik.errors.mobile}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="role">Role</Label>
                <Input
                  type="select"
                  id="roleId"
                  name="roleId"
                  value={formik.values.roleId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Role</option>
                  {roles.map(role => (
                    <option key={role.subRoleId} value={role.subRoleId}>
                      {roleAliases[role.roleName] || role.roleName}
                    </option>
                  ))}
                </Input>
                {formik.touched.roleId && formik.errors.roleId && (
                  <div className="text-danger">{formik.errors.roleId}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="dateOfBirth">Date of Birth</Label>
                <Input
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                  <div className="text-danger">{formik.errors.dateOfBirth}</div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="button mb-3" style={{ textAlign: "center" }}>
            <Button type="submit" color="primary">
              Add User
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  )
}

export default AddUsers
