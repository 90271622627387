import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const DonutChart = ({ dataColors, data }) => {
  const aggregatedData = data.reduce((acc, item) => {
    acc.totalAnonymousIncidents += item.totalAnonymousIncidents;
    acc.totalIdentifiedIncidents += item.totalIdentifiedIncidents;
    return acc;
  }, { totalAnonymousIncidents: 0, totalIdentifiedIncidents: 0 });

  if (aggregatedData.totalAnonymousIncidents === 0 && aggregatedData.totalIdentifiedIncidents === 0) {
    return <div>No data available for the selected months.</div>;
  }

  const donutApexChartColors = getChartColorsArray(dataColors);

  const series = [aggregatedData.totalAnonymousIncidents, aggregatedData.totalIdentifiedIncidents];

  const options = {
    labels: ["Total Anonymous Incidents", "Total Identified Incidents"],
    colors: donutApexChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="250"
    />
  );
};

export default DonutChart;
