// ContentChart.js
import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const ContentDonutChart = ({ dataColors, data }) => {
  const aggregatedData = data.reduce((acc, item) => {
    acc.totalContent += item.totalContent;
    acc.totalNewContent += item.totalNewContent;
    acc.totalPublishedContent += item.totalPublishedContent;
    acc.totalArchivedContent += item.totalArchivedContent;
    acc.totalUnPublishedContent += item.totalUnPublishedContent;
    return acc;
  }, { 
    totalContent: 0,
    totalNewContent: 0,
    totalPublishedContent: 0,
    totalArchivedContent: 0,
    totalUnPublishedContent: 0
  });

  if (
    aggregatedData.totalContent === 0 &&
    aggregatedData.totalNewContent === 0 &&
    aggregatedData.totalPublishedContent === 0 &&
    aggregatedData.totalArchivedContent === 0 &&
    aggregatedData.totalUnPublishedContent === 0
  ) {
    return <div>No data available for the selected months.</div>;
  }

  const donutApexChartColors = getChartColorsArray(dataColors);

  const series = [
    aggregatedData.totalContent,
    aggregatedData.totalNewContent,
    aggregatedData.totalPublishedContent,
    aggregatedData.totalArchivedContent,
    aggregatedData.totalUnPublishedContent,
  ];

  const options = {
    labels: [
      "Total Content",
      "Total New Content",
      "Total Published Content",
      "Total Archived Content",
      "Total Unpublished Content",
    ],
    colors: donutApexChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="396"
    />
  );
};

export default ContentDonutChart;
