import React, { useState, useEffect, useRef } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import {
  uploadMedia,
  getContentOptions,
  createContentApi,
} from "api/categories"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"

const EducationalContent = () => {
  document.title = "Educational Content | CA Portal"

  const { id } = useParams()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    contentCategoryId: 5,
  })
  const [file, setFile] = useState(null)
  const [imageUrl, setImageUrl] = useState("")
  const [contentOptions, setContentOptions] = useState([])
  const descriptionRef = useRef(null)
  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchContentOptions = async () => {
      try {
        const response = await getContentOptions(0, 10)
        const filteredData = response.data.filter(item => item.id === 5)
        setContentOptions(filteredData)
      } catch (error) {
        console.error("Error fetching content options:", error)
      }
    }

    fetchContentOptions()
  }, [id])

  const validationSchema = Yup.object({
    title: Yup.string().required("Please Enter Content Title"),
  })

  const formik = useFormik({
    initialValues: {
      title: formData.title || "",
      selectedContent: "",
      description: formData.description || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let ImageUrl = formData.imageUrl || imageUrl

        if (file) {
          const mediaResponse = await uploadMedia(file)
          ImageUrl = mediaResponse.data
        }

        const contentData = {
          ...formData,
          title: values.title,
          description: values.description,
          imageUrl: ImageUrl,
        }

        await createContentApi(contentData)

        alert("Content created successfully")
        navigate("/learningcentre")
      } catch (error) {
        console.error("Error updating content:", error)
        alert("Failed to create content. Please try again.")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      setFile(file)
      const fileUrl = URL.createObjectURL(file)
      setImageUrl(fileUrl)
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    setImageUrl("")
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    formik.setFieldValue("mediafile", "")
  }

  const handleCancel = () => {
    navigate("/learningcentre")
  }

  const handleInput = event => {
    event.target.style.height = "auto"
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = "auto"
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`
    }
  }, [formik.values.description])

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumbs
            title="Content"
            breadcrumbItem="Create Educational Content"
          />
          <Link to="/learningcentre">
            <Button type="button" color="primary">
              <i className="bx bx-arrow-back"></i> Back
            </Button>
          </Link>
        </div>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Col>
                  <Card style={{ borderRadius: "10px", marginTop: "10px" }}>
                    <CardBody className="p-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={formik.handleSubmit}
                      >
                        <FormGroup>
                          <Label for="title">Title</Label>
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            className="form-control"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.title && !!formik.errors.title
                            }
                          />
                          {formik.touched.title && formik.errors.title && (
                            <FormFeedback>{formik.errors.title}</FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <div className="mb-3">
                            <label className="form-label">
                              Click to Upload Media Here (Optional)
                            </label>
                            <div className="d-flex align-items-center">
                              <input
                                type="file"
                                className="form-control flex-grow-1 me-3"
                                name="mediafile"
                                placeholder="Click to Upload Media Here"
                                id="mediafile"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                accept=".pdf,.doc,.docx,.ppt,.pptx,image/*,video/*"
                              />
                              {imageUrl && (
                                <div className="d-flex align-items-center">
                                  <img
                                    src={imageUrl}
                                    alt="Uploaded"
                                    style={{ maxWidth: "100px" }}
                                  />
                                  <Button
                                    color="danger"
                                    onClick={handleRemoveFile}
                                    className="ms-3"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup>
                          <Label for="description">Description:</Label>
                          <Input
                            type="textarea"
                            id="description"
                            name="description"
                            innerRef={descriptionRef}
                            value={formik.values.description}
                            onChange={event => {
                              formik.handleChange(event)
                              handleInput(event)
                            }}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.description &&
                              !!formik.errors.description
                            }
                            style={{ overflow: "hidden", resize: "none" }}
                          />
                          {formik.touched.description &&
                            formik.errors.description && (
                              <FormFeedback>
                                {formik.errors.description}
                              </FormFeedback>
                            )}
                        </FormGroup>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            color="success"
                            style={{ marginRight: "10px" }}
                            size="lg"
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            Save
                          </Button>
                          <Button
                            color="primary"
                            size="lg"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EducationalContent
