import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ data }) => {
  const series = [
    {
      name: "Total Surveys",
      data: data.map(item => item.totalSurveys),
    },
    {
      name: "Total Respondents",
      data: data.map(item => item.totalRespondents),
    },
    {
      name: "Total Running Surveys",
      data: data.map(item => item.totalRunningSurveys),
    },
    {
      name: "Total Closed Surveys",
      data: data.map(item => item.totalClosedSurveys),
    }
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: data.map(item => item.monthName),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      },
      
      tickAmount: 5,
    },
  };

  return (
    <ReactApexChart 
    options={options} 
    series={series}
     type="bar"
     height="350"/>
  );
};

export default BarChart;
