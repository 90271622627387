import React, { useState } from "react";
import { Container, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";


const Feedback = () => {
    document.title = "Survey Response";

   
    const feedbackTopics = [
        {
            id: 1,
            title: "Feature Requests",
            description: "Feedback related to new features",
        },
        {
            id: 2,
            title: "User Experience",
            description: "Feedback on the usability of the platform",
        },
        {
            id: 3,
            title: "Bug Reports",
            description: "Issues and bugs encountered",
        },
    ];

    const [selectedTopic, setSelectedTopic] = useState(null);

    const handleTopicSelect = (topic) => {
        setSelectedTopic(topic);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Feedbacks" breadcrumbItem="View Feedbacks" />
                <h4 className="mt-3 mb-3">Survey Response</h4>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbackTopics.map((topic, index) => (
                            <tr key={topic.id}>
                                <td>{index + 1}</td>
                                <td>{topic.title}</td>
                                <td>{topic.description}</td>
                                <td>
                                    <Button
                                        color="primary"
                                        onClick={() => handleTopicSelect(topic)}
                                    >
                                        View Feedback
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {selectedTopic && (
                    <div className="mt-4">
                        <h5>{selectedTopic.title}</h5>
                        <p>{selectedTopic.description}</p>
                        <ul>
                            <li>Feedback Entry 1</li>
                            <li>Feedback Entry 2</li>
                            <li>Feedback Entry 3</li>
                        </ul>
                        <Link to="/feedback">
                            <Button color="secondary">Back to Response</Button>
                        </Link>
                    </div>
                )}
            </Container>
        </div>
    );
};

export default Feedback;
