import React, { useState, useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "../../components/Common/TableContainer";
import { getContentOptions, deleteContentCategoryApi } from "api/categories";
import { useNavigate } from "react-router-dom";
import { useRole } from "hooks/useRole";
import { useMutation } from "react-query";

const ContentCategoryList = () => {
  React.useEffect(() => {
    document.title = "Content Categories | CA Portal";
  }, []);

  const [pagination, setPagination] = useState({
    pageSize: 100,
    pageNumber: 0,
  });

  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole();

  const navigate = useNavigate();

  const [contentOptions, setContentOptions] = useState([]);

  const { mutate: mutateContentCategories } = useMutation(
    (params) => getContentOptions(params.pageNumber, params.pageSize),
    {
      onSuccess: (res) => {
        const data = Array.isArray(res) ? res : res.data || [];
        setContentOptions(data);
        console.log(data);
      },
      onSettled: () => {},
    }
  );

  useEffect(() => {
    mutateContentCategories({ ...pagination });
  }, [pagination]);

  const columns = useMemo(
    () => [
      {
        Header: "#ID",
        accessor: "id",
      },
      {
        Header: "Content Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          return formattedDate;
        },
      },
    ],
    []
  );

  const handleCreate = () => {
    navigate("/createcontentcategory");
  };

  const handleViewDetails = (record) => {
    navigate(`/contentcategorydetails/${record.original.id}`);
  };

  const handleEdit = (record) => {
    navigate(`/contentcategorylist/${record.original.id}/edit`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteContentCategoryApi(id);
      mutateContentCategories({ ...pagination });
    } catch (error) {
      alert("Error deleting content category: " + error.message);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Content" breadcrumbItem="View Content Categories" />
        <TableContainer
          showEdit={isAdmin || isSuperAdmin || isEditor}





































































































































































































































































































































































































































































































































































































          showView
          showDelete={isAdmin || isSuperAdmin}
          columns={columns}
          data={contentOptions || []}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          isPagination={true}
          iscustomPageSizeOptions={true}
          // addText="Add Content Category"
          tableClass="align-middle table-nowrap table-check table"
          theadClass="table-light"
          paginationDiv="col-12"
          pagination="justify-content-left pagination pagination-rounded"
          handleView={handleViewDetails}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          fetchData={mutateContentCategories}
        />
      </Container>
    </div>
  );
};

export default ContentCategoryList;
