import React, { useState, useEffect } from "react"
import {
  Container,
  Button,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle,
  InputGroupText,
  InputGroup,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import {
  getUserDetailsById,
  confirmOTP,
  sendOTP,
  verifyEmail,
  verifyMobile,
  updateUserDetails,
} from "api/users"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useNavigate, Link } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ChangePasswordModal from "components/Modals/ChangePasswordModal"

const Test = () => {
  document.title = "User Details | CA Portal"

  // const navigate = useNavigate()
  const [userDetailsData, setUserDetailsData] = useState({})
  const [userData, setUserData] = useState({})
  const [modalStandard, setModalStandard] = useState(false)
  const [modalMobile, setModalMobile] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [emailCheckboxEnabled, setEmailCheckboxEnabled] = useState(true)
  const [mobileCheckboxEnabled, setMobileCheckboxEnabled] = useState(true)
  const [mfaCheckboxEnabled, setMfaCheckboxEnabled] = useState(true)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isMobileVerified, setIsMobileVerified] = useState(false)
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [isMfaEnabled, setIsMfaEnabled] = useState(false)

  const id = localStorage.getItem("appUserId")

  const handleBack = () => {
    navigate("/dashboard")
  }

  const handleUpdateModalToggle = () => {
    setShowUpdateModal(!showUpdateModal)
  }

  const handleDropdownToggle = () => {
    setSettingsMenu(!settingsMenu)
  }

  const toggleStandard = () => setModalStandard(!modalStandard)
  const toggleMobile = () => setModalMobile(!modalMobile)

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getUserDetailsById(id)
        if (response.status === 200) {
          const unformattedData = response.data
          setUserData(unformattedData)
          const formattedData = formatDates(response.data)
          setUserDetailsData(formattedData)
          setIsMfaEnabled(formattedData.mfaEnabled)
          setIsEmailVerified(formattedData.isEmailVerified)
          setIsMobileVerified(formattedData.isMobileVerified)
          if (formattedData.mfaEnabled) {
            setMfaCheckboxEnabled(false)
          }
          if (formattedData.isEmailVerified) {
            setEmailCheckboxEnabled(false)
          }
          if (formattedData.isMobileVerified) {
            setMobileCheckboxEnabled(false)
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error)
      }
    }

    fetchUserDetails()
  }, [id])

  const aliases = {
    id: "ID",
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    email: "Email",
    mobile: "Mobile",
    dateOfBirth: "DOB",
    sex: "Gender",
    preferredLanguage: "Preferred Language",
    roles: "Role",
  }

  const roleAliases = {
    ROLE_ADMIN: "Admin",
    ROLE_SUPER_ADMIN: "Super Admin",
    ROLE_ANALYST: "Analyst",
    ROLE_EDITOR: "Editor",
    ROLE_USER: "User",
  }

  const getRoleAlias = roleName => roleAliases[roleName] || roleName

  const formatDate = dateString => {
    const date = new Date(dateString)
    return date.toLocaleString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  }

  const formatDates = data => {
    const formattedData = { ...data }
    formattedData.dateOfBirth = formatDate(data.dateOfBirth)
    return formattedData
  }

  const updateUserEmailVerification = async () => {
    try {
      const userUpdateData = { ...userData, isEmailVerified: true }
      await updateUserDetails(id, userUpdateData)
      setIsEmailVerified(true)
      alert("Email Verification enabled successfully.")
    } catch (error) {
      alert("Error verifying email. Please try again.")
    }
  }

  const toggleOffEmailVerification = async () => {
    try {
      const userUpdateData = { ...userData, isEmailVerified: false }
      await updateUserDetails(id, userUpdateData)
      setIsEmailVerified(false)
      alert("Email Verification disabled successfully.")
    } catch (error) {
      alert("Error turning off email verification. Please try again")
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    otp: Yup.string().required("Code is required"),
  })

  const validation = useFormik({
    initialValues: {
      email: userData.email || "",
      otp: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const verificationData = {
          email: values.email,
          otp: values.otp,
        }

        await verifyEmail(id, verificationData)
        updateUserEmailVerification()
        alert("Email Verification Successful!")
        // setIsEmailVerified(true)
        setModalStandard(false)
        // setEmailCheckboxEnabled(false) // Disable email checkbox permanently
      } catch (error) {
        alert("Error verifying email. Please try again")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleSendOTP = async () => {
    try {
      const sendOtp = {
        email: validation.values.email,
      }
      await sendOTP(sendOtp)
      alert("Code sent to your email!")
      setCodeSent(true)
    } catch (error) {
      alert("Error sending code. Please try again.")
      setCodeSent(false)
    }
  }

  const updateUserMobileVerification = async () => {
    try {
      const userUpdateData = { ...userData, isMobileVerified: true }
      await updateUserDetails(id, userUpdateData)
      setIsMobileVerified(true)
      alert("Mobile Verification enabled successfully.")
    } catch (error) {
      alert("Error verifying mobile. Please try again.")
    }
  }
  const toggleOffMobileVerification = async () => {
    try {
      const userUpdateData = { ...userData, isMobileVerified: false }
      await updateUserDetails(id, userUpdateData)
      setIsMobileVerified(false)
      alert("Mobile Verification disabled successfully.")
    } catch (error) {
      alert("Error turning off mobile verification. Please try again")
    }
  }

  const validationSchemaMobile = Yup.object().shape({
    mobile: Yup.string().required("Mobile is required"),
    otp: Yup.string().required("Code is required"),
  })

  const formik = useFormik({
    initialValues: {
      mobile: userData.mobile || "",
      otp: "",
    },
    validationSchema: validationSchemaMobile,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const verificationData = {
          mobile: values.mobile,
          otp: values.otp,
        }

        await verifyMobile(id, verificationData)
        updateUserMobileVerification()
        alert("Mobile Verification Successful!")
        setModalMobile(false)
        // setMobileCheckboxEnabled(false) // Disable mobile checkbox permanently
      } catch (error) {
        alert("Error verifying mobile. Please try again")
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleSendMobileOTP = async () => {
    try {
      const sendOtp = {
        mobile: formik.values.mobile,
      }
      await sendOTP(sendOtp)
      alert("Code sent to your phone number!")
      setCodeSent(true)
    } catch (error) {
      alert("Error sending code. Please try again.")
      setCodeSent(false)
    }
  }

  const updateUserMfaStatus = async enabled => {
    try {
      const userUpdateData = { ...userData, mfaEnabled: enabled }
      await updateUserDetails(id, userUpdateData)
      setIsMfaEnabled(enabled)
      getUserDetailsById(id)
      alert("MFA " + (enabled ? "enabled" : "disabled") + " successfully")
    } catch (error) {
      alert("Error updating MFA status. Please try again.")
    }
  }

  const handleCheckboxChange = async e => {
    e.preventDefault()
    const { id, checked } = e.target

    if (id === "emailCheckbox") {
      if (isEmailVerified && !checked) {
        if (isMfaEnabled) {
          alert(
            "Please disable MFA first before turning off email verification."
          )
        } else {
          await toggleOffEmailVerification()
        }
      } else if (!isEmailVerified && checked) {
        setModalStandard(true)
      }
    }

    if (id === "mobileCheckbox") {
      if (isMobileVerified && !checked) {
        if (isMfaEnabled) {
          alert(
            "Please disable MFA first before turning off mobile verification."
          )
        } else {
          await toggleOffMobileVerification()
        }
      } else if (!isMobileVerified && checked) {
        setModalMobile(true)
      }
    }

    if (id === "mfaCheckbox") {
      if (!isEmailVerified && !isMobileVerified) {
        alert("Please verify your email or mobile first.")
      } else {
        setIsMfaEnabled(!isMfaEnabled) // Toggle state
        updateUserMfaStatus(!isMfaEnabled) // Toggle MFA status on API
      }
    }
  }

  const cleanUserDetailsData = data => {
    return Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    )
  }

  const cleanedUserDetailsData = cleanUserDetailsData(userDetailsData)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex justify-content-between mb-3">
          <Breadcrumb title="Profile" breadcrumbItem="User Details" />

          <Button tag={Link} to="/dashboard" color="primary" className="btn">
            <i className="bx bx-arrow-back"></i> Back
          </Button>
        </div>
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col
                  xs={12}
                  className="d-flex justify-content-end align-items-center ml-2"
                >
                  <Dropdown isOpen={settingsMenu} toggle={handleDropdownToggle}>
                    <DropdownToggle tag="button" className="btn btn-primary">
                      <i className="bx bx-align-right" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end p-3">
                      <DropdownItem
                        className="d-flex align-items-center py-2"
                        onClick={handleUpdateModalToggle}
                      >
                        <div className="mb-3">
                          <i className="bx bxs-shield-alt-2 me-2"></i>
                          <span>Change Password</span>
                        </div>
                      </DropdownItem>
                      <DropdownItem className="d-flex align-items-center py-2">
                        <div className="form-check form-switch mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mfaCheckbox"
                            onChange={handleCheckboxChange}
                            // disabled={!emailCheckboxEnabled}
                            checked={isMfaEnabled}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mfaCheckbox"
                          >
                            Enable Multi-factor Authentication(MFA)
                          </label>
                        </div>
                      </DropdownItem>
                      <DropdownItem className="d-flex align-items-center py-2">
                        <div className="form-check form-switch mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="emailCheckbox"
                            onChange={handleCheckboxChange}
                            // disabled={!emailCheckboxEnabled}
                            checked={isEmailVerified}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emailCheckbox"
                          >
                            Verify Email
                          </label>
                        </div>
                      </DropdownItem>
                      <DropdownItem className="d-flex align-items-center py-2">
                        <div className="form-check form-switch mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="mobileCheckbox"
                            onChange={handleCheckboxChange}
                            // disabled={!mobileCheckboxEnabled}
                            checked={isMobileVerified}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="mobileCheckbox"
                          >
                            Verify Mobile
                          </label>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <div className="table-responsive">
                  <Table className="table mb-4">
                    <tbody>
                      {Object.entries(cleanedUserDetailsData)
                        .filter(
                          ([key]) =>
                            ![
                              "deleted",
                              "userType",
                              "status",
                              "profileId",
                              "profileUrl",
                              "isEmailVerified",
                              "isMobileVerified",
                              "joinedForum",
                              "forumUserId",
                              "mfaEnabled",
                              "onseSignalId"
                            ].includes(key)
                        )
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>
                              <strong>{aliases[key] || key}</strong>
                            </td>
                            <td>
                              {key === "roles"
                                ? value.map(role => (
                                    <span key={role.roleId}>
                                      {getRoleAlias(role.roleName)}
                                    </span>
                                  ))
                                : typeof value === "object"
                                ? JSON.stringify(value)
                                : value}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </CardBody>
          </Card>

          <ChangePasswordModal
            isOpen={showUpdateModal}
            toggle={handleUpdateModalToggle}
            userId={id}
          />

          <Modal isOpen={modalStandard} toggle={toggleStandard}>
            <ModalHeader>Verify Email Address</ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <InputGroup>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                      }
                    />
                    <InputGroupText addonType="append">
                      {/* {!codeSent && ( */}
                      <Button color="primary" onClick={handleSendOTP}>
                        Send Code
                      </Button>
                      {/* )} */}
                    </InputGroupText>
                  </InputGroup>
                  {validation.touched.email && validation.errors.email && (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  )}
                </div>
                {/* {codeSent && ( */}
                <>
                  <div className="mb-3">
                    <Label className="form-label">Verification Code</Label>
                    <Input
                      name="otp"
                      className="form-control"
                      placeholder="Enter Code"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.otp || ""}
                      invalid={validation.touched.otp && validation.errors.otp}
                    />
                    {validation.touched.otp && validation.errors.otp && (
                      <FormFeedback type="invalid">
                        {validation.errors.otp}
                      </FormFeedback>
                    )}
                  </div>

                  <Row className="mb-3">
                    <Col className="text-end">
                      {validation.values.otp && (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={
                            !validation.isValid || validation.isSubmitting
                          }
                        >
                          Verify Email
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
                {/* )} */}
              </Form>
            </ModalBody>
          </Modal>

          <Modal isOpen={modalMobile} toggle={toggleMobile}>
            <ModalHeader>Verify Phone Number</ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  formik.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Mobile</Label>
                  <InputGroup>
                    <Input
                      name="mobile"
                      className="form-control"
                      placeholder="Enter mobile"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile || ""}
                      invalid={
                        formik.touched.mobile && formik.errors.mobile
                          ? true
                          : false
                      }
                    />
                    <InputGroupText addonType="append">
                      {/* {!codeSent && ( */}
                      <Button color="primary" onClick={handleSendMobileOTP}>
                        Send Code
                      </Button>
                      {/* )} */}
                    </InputGroupText>
                  </InputGroup>
                  {formik.touched.mobile && formik.errors.mobile && (
                    <FormFeedback type="invalid">
                      {formik.errors.mobile}
                    </FormFeedback>
                  )}
                </div>
                {/* {codeSent && ( */}
                <>
                  <div className="mb-3">
                    <Label className="form-label">Verification Code</Label>
                    <Input
                      name="otp"
                      className="form-control"
                      placeholder="Enter Code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.otp || ""}
                      invalid={formik.touched.otp && formik.errors.otp}
                    />
                    {formik.touched.otp && formik.errors.otp && (
                      <FormFeedback type="invalid">
                        {formik.errors.otp}
                      </FormFeedback>
                    )}
                  </div>

                  <Row className="mb-3">
                    <Col className="text-end">
                      {formik.values.otp && (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Verify Mobile
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
                {/* )} */}
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Test
