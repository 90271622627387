import { get, post, del, put} from './utils';

export async function getRoles(data) {
    return get('/roles', data);
}

export async function adminCreateUsers(data) {
    return post('/users', data);
}

export async function getUsers(page, size) {
    return get(`/users?page=${page}&size=${size}`);
  }

export async function getfeedbacks(data) {
    return get('/feedbacks', data);
}

export async function addVisitReason(data) {
    return post('/feedback-reasons', data);
}

export async function getVisitReasonById(id) {
  return get(`/feedback-reasons/${id}`)
}

export async function getVisitReasons(page, size) {
  return get(`/feedback-reasons?page=${page}&size-=${size}`)
}

export async function editVisitReason(data) {
  return put(`/feedback-reasons`, data)
}

export async function deleteVisitReason(id) {
  return del(`/feedback-reasons/${id}`)
}

export async function getUserDetailsById(id) {
    return get(`/users/${id}`);
  }
  
export async function deleteUserById(id) {
    return del(`/users/${id}`);
}

export async function updateUserDetails(id, data) {
  return put(`/users/${id}`, data)
}

export async function changeUserPassword(id, data) {
  return put(`/users/${id}/password`, data)
}

export async function sendOTP(data) {
  return put(`/users/send-otp`, data)
}

export async function confirmOTP(data) {
  return put(`/users/confirm-otp`, data)
}

export async function verifyEmail(id, data) {
  return put(`/users/${id}/verify-email`, data)
}

export async function verifyMobile(id, data) {
  return put(`/users/${id}/verify-mobile`, data)
}