import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getUserDetailsById, updateUserDetails } from "api/users";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditUserDetails = () => {
  useEffect(() => {
    document.title = "Edit User Details | CA Portal";
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserDetailsById(id);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (id) {
      fetchUserData();
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      formik.setValues({
        firstName: userData.firstName || "",
        middleName: userData.middleName || "",
        lastName: userData.lastName || "",
        sex: userData.sex || "",
        dateOfBirth: userData.dateOfBirth ? new Date(userData.dateOfBirth) : null,
        preferredLanguage: userData.preferredLanguage || "",
        mobile: userData.mobile || ""
      });
    }
  }, [userData]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Please Enter First Name"),
    lastName: Yup.string().required("Please Enter Last Name"),
    sex: Yup.string().required("Please Select Sex"),
    dateOfBirth: Yup.date().required("Please Select Date Of Birth"),
    preferredLanguage: Yup.string().required("Please Select Preferred Language"),
    mobile: Yup.string().required("Please Enter Mobile Number")
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      sex: "",
      dateOfBirth: null,
      preferredLanguage: "",
      mobile: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const userData = {
          firstName: values.firstName || "",
          middleName: values.middleName || "",
          lastName: values.lastName || "",
          sex: values.sex || "",
          dateOfBirth: values.dateOfBirth ? values.dateOfBirth.toISOString() : "",
          preferredLanguage: values.preferredLanguage || "",
          mobile: values.mobile || ""
        };
        await updateUserDetails(id, userData);
        navigate("/users");
        setError(null);
      } catch (error) {
        console.error("Error updating user details:", error);
        setError("Failed to update user details. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleBack = () => {
    navigate("/users");
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Users" breadcrumbItem="Edit User Details" />
        <Row className="justify-content-center">
          <Col lg={12}>
            <Card>
              <CardBody>
                {userData && (
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="firstName">First Name</Label>
                          <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.firstName && !!formik.errors.firstName}
                          />
                          {formik.touched.firstName && formik.errors.firstName && (
                            <FormFeedback>{formik.errors.firstName}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="middleName">Middle Name</Label>
                          <Input
                            type="text"
                            name="middleName"
                            id="middleName"
                            value={formik.values.middleName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.middleName && !!formik.errors.middleName}
                          />
                          {formik.touched.middleName && formik.errors.middleName && (
                            <FormFeedback>{formik.errors.middleName}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="lastName">Last Name</Label>
                          <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.lastName && !!formik.errors.lastName}
                          />
                          {formik.touched.lastName && formik.errors.lastName && (
                            <FormFeedback>{formik.errors.lastName}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="sex">Sex</Label>
                          <Input
                            type="select"
                            id="sex"
                            name="sex"
                            value={formik.values.sex}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Sex</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                          </Input>
                          {formik.touched.sex && formik.errors.sex && (
                            <FormFeedback>{formik.errors.sex}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="dateOfBirth">Date of Birth</Label>
                          <DatePicker
                            selected={formik.values.dateOfBirth}
                            onChange={date => formik.setFieldValue("dateOfBirth", date)}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.touched.dateOfBirth && formik.errors.dateOfBirth ? "is-invalid" : ""}`}
                          />
                          {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                            <FormFeedback>{formik.errors.dateOfBirth}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="preferredLanguage">Preferred Language</Label>
                          <Input
                            type="select"
                            id="preferredLanguage"
                            name="preferredLanguage"
                            value={formik.values.preferredLanguage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Preferred Language</option>
                            <option value="ENGLISH">English</option>
                            <option value="KISWAHILI">Kiswahili</option>
                          </Input>
                          {formik.touched.preferredLanguage && formik.errors.preferredLanguage && (
                            <FormFeedback>{formik.errors.preferredLanguage}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="mobile">Mobile</Label>
                          <Input
                            type="text" // Changed to text to avoid unwanted increment/decrement behavior
                            name="mobile"
                            id="mobile"
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.mobile && !!formik.errors.mobile}
                          />
                          {formik.touched.mobile && formik.errors.mobile && (
                            <FormFeedback>{formik.errors.mobile}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit" color="primary" style={{ marginRight: "10px" }}>
                        Update
                      </Button>
                      <Button color="success" onClick={handleBack} style={{ marginLeft: "10px" }}>
                        Back
                      </Button>
                    </div>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditUserDetails;
