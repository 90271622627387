import React, { Fragment, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  usePagination,
} from "react-table";
import { useTranslation } from 'react-i18next';
import { Table, Row, Col, UncontrolledTooltip, Button } from "reactstrap"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { useNavigate, Link, useLocation } from "react-router-dom";
import DeleteModal from './DeleteModal';

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
    </React.Fragment>
  )
}

const TableContainer = ({
  addText = "Add New",
  showEdit,
  showDelete,
  showView,
  hasCreate,
  handleEdit,
  handleDelete,
  handleView,
  handleCreate,
  rootPath,
  columns,
  data,
  resource,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass,
  fetchData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    // useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }
  const onChangeInSelect = event => {
    const pageSize = Number(event.target.value);
    onChangePagination({ limit: pageSize, page: paginationData.pageNumber })
    setPageSize(pageSize)
  }

  const onCreate = () => {
    if (handleCreate) {
      handleCreate();
    } else {
      navigate(`${rootPath || location.pathname}/create`);
    }
  };

  const onEdit = (record) => {
    if (handleEdit) {
      handleEdit(record);
    } else {
      navigate(`${rootPath || location.pathname}/${record.id}/edit`);
    }
  };

  const onView = (record) => {
    if (handleView) {
      handleView(record);
    } else {
      navigate(`${rootPath || location.pathname}/${record.id}`);
    }
  };

  const onDelete = (record) => {
    setId(record?.original?.id);
    setDeleteModal(true);
  };


  const handleDeleteConfirmed = async () => {
    try {
      await handleDelete(id);
      setDeleteModal(false);

      if (fetchData) {
        fetchData();
      }
    } catch (error) {
      alert(error.message);
    }
  };
  

  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions &&
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        }

        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {hasCreate && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={onCreate}
              >
                <i className="mdi mdi-plus me-1" />
                {addText/* {t(`${resource}.add`)} */}
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
          </Col>
        )}
      </Row>

      <div className="table-responsive">
      <Table {...getTableProps()} className={tableClass} style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} className={column.isSort ? "sorting" : ''}>
                    <div className="m-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
                <th className={''}>
                  <div className="m-0">
                    {"Actions"}
                  </div>
                </th>
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                    <td>
                      <div className="d-flex gap-3">
                        {
                          showEdit &&
                          <Button
                            color="link"
                            className="text-success p-0 m-0"
                            onClick={() => onEdit(row)}
                          >
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                              Edit
                            </UncontrolledTooltip>
                          </Button>
                        }
                        {
                          showView && <Button
                            color="link"
                            className="text-primary p-0 m-0"
                            onClick={() => onView(row)}
                          >
                            <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                              View
                            </UncontrolledTooltip>
                          </Button>
                        }
                        {
                          showDelete &&
                          <Button
                            color="link"
                            className="text-danger p-0 m-0"
                            onClick={() => onDelete(row)}
                          >
                            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                              Delete
                            </UncontrolledTooltip>
                          </Button>
                        }
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </div>

      {
        isPagination && (
          <Row className="justify-content-between align-items-center">
            {isShowingPageLength && <div className="col-sm">
              <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
            </div>}
            <div className={paginationDiv}>
              <ul className={pagination}>
                <li className={`page-item ${!canPreviousPage ? "disabled" : ''}`}>
                  <Link to="#" className="page-link" onClick={previousPage}>
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </li>
                {pageOptions.map((item, key) => (
                  <React.Fragment key={key}>
                    <li className={pageIndex === item ? "page-item active" : "page-item"}>
                      <Link to="#" className="page-link" onClick={() => gotoPage(item)}>{item + 1}</Link>
                    </li>
                  </React.Fragment>
                ))}
                <li className={`page-item ${!canNextPage ? "disabled" : ''}`}>
                  <Link to="#" className="page-link" onClick={nextPage}>
                    <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Row>
        )
      }
      <DeleteModal
        id={id}
        show={deleteModal}
        onDeleteClick={handleDeleteConfirmed}
        onCloseClick={() => setDeleteModal(false)}
      />
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
