import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { getTopics, getTopicSlug } from "../../api/survey"
import { format } from "date-fns"
import { useRole } from "hooks/useRole"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const ForumIndex = () => {
  const { isAdmin, isEditor, isAnalyst, isSuperAdmin } = useRole()

  const [selectedTopic, setSelectedTopic] = useState(null)
  const [topics, setTopics] = useState([])
  const [topicData, setTopicData] = useState(null)
  const [visibleTopics, setVisibleTopics] = useState(3)
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredTopics, setFilteredTopics] = useState([])

  useEffect(() => {
    document.title = "Forum | CA Portal"
    fetchTopics()
  }, [])

  useEffect(() => {
    if (selectedTopic) {
      fetchTopicData(selectedTopic)
    }
  }, [selectedTopic])

  const fetchTopics = async () => {
    try {
      const response = await getTopics()
      setTopics(response.data)
      setFilteredTopics(response.data)
      if (response.data.length > 0) {
        setSelectedTopic(response.data[0].slug)
      }
    } catch (error) {
      console.error("Error fetching topics:", error)
    }
  }

  const fetchTopicData = async slug => {
    try {
      const response = await getTopicSlug(slug)
      setTopicData(response.data)
    } catch (error) {
      console.error("Error fetching topic data:", error)
    }
  }

  const formatTimestamp = timestampISO => {
    const date = new Date(timestampISO)
    return format(date, "EEE dd MMMM 'at' HH:mm")
  }

  const renderPostCount = postcount => {
    return postcount === 1 ? "1 post" : `${postcount} posts`
  }

  const handleItemClick = slug => {
    setSelectedTopic(slug)
  }

  const loadMoreTopics = () => {
    setVisibleTopics(prevCount => prevCount + 3)
  }

  const handleSearchChange = e => {
    const query = e.target.value.toLowerCase()
    setSearchQuery(query)
    const filtered = topics.filter(topic =>
      topic.title.toLowerCase().includes(query)
    )
    setFilteredTopics(filtered)
  }

  const shareUrl = window.location.href
  const shareTitle = topicData?.title || "Check out this content!"
  const shareDescription = topicData?.description || ""
  const appLink =
    "Get more information about this from our app on Playstore: https://play.google.com/store/apps/details?id=ca"
  const shareText = `${shareTitle} - ${shareDescription}\n\n${appLink}`
  const mediaShareUrl = topicData?.imageUrl || shareUrl

  return (
    <div className="page-content">
      <Breadcrumbs title="Forum" breadcrumbItem="Forum Content" />
      <Container fluid>
        <Row>
          <Col xl={3} lg={4}>
            <Card>
              <CardBody className="p-4">
                <div className="search-box">
                  <p className="text-muted">Search a Topic</p>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control rounded bg-light border-light"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
                <hr className="my-4 hr-dark" />
                <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                  <ul className="list-unstyled fw-medium">
                    {filteredTopics.slice().map(topic => (
                      <li key={topic.cid}>
                        <Link
                          to="#"
                          onClick={() => handleItemClick(topic.slug)}
                          className={`text-primary ${
                            selectedTopic === topic.slug ? "fw-bold" : ""
                          }`}
                        >
                          <p
                            className="text-primary"
                            style={{ fontSize: "larger" }}
                          >
                            {topic.title}
                          </p>
                        </Link>
                        <p style={{ fontSize: "smaller" }}>
                          {formatTimestamp(topic.timestampISO)}
                        </p>
                        <p style={{ fontSize: "smaller" }}>
                          {renderPostCount(topic.postcount)}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                <hr className="my-4 hr-dark" />
              </CardBody>
            </Card>
          </Col>
          <Col xl={9} lg={8}>
            {topicData && (
              <Card>
                {/* <Col
                  xs={12}
                  className="d-flex justify-content-end align-items-center mt-2"
                >
                  {(isAdmin || isSuperAdmin) && (
                    <>
                      <div className="ml-2">
                        <FacebookShareButton
                          url={mediaShareUrl}
                          quote={shareText}
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </div>
                      <div className="ml-2">
                        <TwitterShareButton
                          url={mediaShareUrl}
                          title={shareText}
                        >
                          <XIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                      <div className="ml-2">
                        <WhatsappShareButton
                          url={mediaShareUrl}
                          title={shareText}
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </div>
                    </>
                  )}
                </Col> */}
                <TabContent className="p-4" activeTab="1">
                  <TabPane tabId="1">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <h3>{topicData.title}</h3>
                        </div>
                        <div style={{ textAlign: "right" }}>
                          <p className="text-muted">
                            {renderPostCount(topicData.postcount)}
                          </p>
                          <p className="text-muted">
                            {topicData.locked === 1 ? "Locked" : "Open"}
                          </p>
                        </div>
                      </div>
                      {topicData.posts.map((post, index) => (
                        <div key={index}>
                          <p
                            dangerouslySetInnerHTML={{ __html: post.content }}
                          ></p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p
                              className="text-muted"
                              style={{ fontSize: "smaller" }}
                            >
                              Posted By: {post.user.username}
                            </p>
                            <p
                              className="text-muted"
                              style={{ fontSize: "smaller" }}
                            >
                              {formatTimestamp(post.timestamp)}
                            </p>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </TabPane>
                </TabContent>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ForumIndex
